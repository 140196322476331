<template>
  <div class="mainContainer">
    <!-- Título principal -->
    <div
      class="d-flex mb-10 my-8 flex-wrap sticky-card py-8 titleContainer"
      width="100%"
    >
      <v-btn icon rounded @click="$router.go(-1)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dense v-bind="attrs" v-on="on">
              <v-icon size="28">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Volver</span>
        </v-tooltip>
      </v-btn>
      <div class="d-flex">
        <h3 class="mt-2" v-if="cliente.Nombre">
          {{ cliente.Fichan }} | {{ cliente.Nombre }}
        </h3>
        <v-tooltip v-if="licen.Var8" bottom>
          <!-- Botón buscar -->
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-2"
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="activeSearchCliente = true"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Buscar fichas</span>
        </v-tooltip>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex">
        <h1 class="justify-center mr-5 mt-1" @click="btnGoToRefFichaMedica">
          Ficha médica
        </h1>
        <v-btn
          v-if="cliente.Nombre"
          icon
          color="primary"
          @click="btnGoToRefFichaMedica"
          ><v-icon>mdi-eye-arrow-right-outline</v-icon></v-btn
        >
      </div>
      <v-spacer></v-spacer>
      <!-- Acciones a la derecha del título -->
      <div v-if="licen.Var8 && cliente.Nombre" class="actions_title">
        <!-- boton enviar email -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              @click="btnActiveShowEmail"
              class="mr-2"
            >
              <v-icon>mdi-email-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Enviar email</span>
        </v-tooltip>
        <!-- Vencimientos futuros -->
        <v-btn
          color="primary"
          class="mr-2 text-capitalize rounded-lg"
          @click="btnActiveNewServis"
          >Vencimientos futuros</v-btn
        >
        <!-- Nueva receta -->
        <div class="btn_container">
          <v-btn
            color="primary"
            class="text-capitalize rounded-lg"
            @click="btnActiveShowReceta"
            outlined
          >
            Nueva receta
          </v-btn>
          <a href="#newPrescription"></a>
        </div>
        <!-- Menú que despliega opciones de archivos y certificados -->
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="ml-2">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="btnActiveShowChecklist">
                <v-list-item-title>Checklist</v-list-item-title>
              </v-list-item>
              <v-divider class="ml-3 mr-3"></v-divider>
              <v-list-item @click="activeDialogoHospitalizar = true">
                <v-list-item-title>Hospitalizar</v-list-item-title>
              </v-list-item>
              <v-divider class="ml-3 mr-3"></v-divider>
              <v-list-item @click="btnActiveShowArchivos">
                <v-list-item-title>Archivos</v-list-item-title>
              </v-list-item>

              <v-divider class="ml-3 mr-3"></v-divider>
              <v-list-item @click="btnActiveShowCertificado">
                <v-list-item-title>Certificados</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-dialog
            v-model="activeDialogoHospitalizar"
            persistent
            scrollable
            max-width="350px"
          >
            <v-card
              class="rounded-xl pa-3 d-flex justify-center"
              color="white"
              elevation="4"
            >
              <div class="ma-3">
                <div class="d-flex">
                  ¿ Seguro que quiere ingresar al hospital a la <br />
                  máscota {{ cliente.Nombre }} ?
                </div>
                <v-card-text class="pa-3">
                  <p>
                    Esto creará una nueva hospitalización la cual debe completar
                    para finalizar el ingreso
                  </p>
                </v-card-text>
                <v-card-action class="pa-3 d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="ma-2"
                    @click="btnCreateHospitalizacionByFicha"
                    >Confirmar</v-btn
                  >
                  <v-btn
                    color="primary"
                    outlined
                    class="ma-2"
                    @click="activeDialogoHospitalizar = false"
                    >Cancelar</v-btn
                  >
                </v-card-action>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
    <!-- Información principal -->
    <div v-if="licen.Var8">
      <!-- Búsqueda de cliente -->
      <v-card
        v-if="activeSearchCliente"
        width="100%"
        class="pa-3 mb-5 rounded-xl ma-2"
      >
        <v-card-title class="mt-4 ml-10">
          <h2>Buscar cliente</h2>
          <v-spacer></v-spacer>
          <v-btn color="primary" icon @click="activeSearchCliente = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="pa-3">
          <div class="d-flex">
            <v-text-field
              v-model="searchCustomer"
              placeholder="Para buscar por nombre, rut o ficha, debe presionar enter o presionar el boton buscar al final de esté campo"
              @keyup.enter="btnSearchCliente"
              hide-details
              class="mb-1"
            ></v-text-field>
            <v-btn
              color="primary"
              class="mt-2 ml-2 text-capitalize"
              @click="btnSearchCliente"
              >Buscar</v-btn
            >
          </div>
          <v-data-table
            :headers="headersClientes"
            :items="clientes"
            :search="searchCustomer"
            :loading="loadingClientes"
            loading-text="Buscando... Espere"
            class="mt-2"
            dense
            :footer-props="{
              'items-per-page-text': 'Filas por página',
            }"
          >
            <template v-slot:item.action="{ item }">
              <div class="actions pa-1">
                <v-btn
                  icon
                  color="primary"
                  @click="btnSelectFichanCustomer(item)"
                  class="rounded-lg text-capitalize"
                  ><v-icon>mdi-check-circle-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true"> No hay resultados disponibles </v-alert>
            </template>
          </v-data-table>
          <v-row class="pa-0 ma-0" v-if="activeShowSelectAtenciondiaByCliente">
            <v-col>
              <v-divider
                class="d-flex d-sm-row d-md-none d-lg-none d-xl-none"
              ></v-divider>
              <h4 class="d-flex mt-5">
                Seleccione una atención para
                <h3 class="ml-1">{{ setUpperString(selectNameCliente) }}</h3>
              </h4>
              <h4>Ficha: {{ selectFichanCliente }}</h4>
              <v-card
                v-for="atenciondia in atenciondiasByCliente"
                elevation="0"
                :key="atenciondia.Id"
                class="pa-1 ma-3"
              >
                <v-row
                  class="d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row ma-0 pa-1 align-center historyRecord_container"
                >
                  <v-col class="ma-0 pa-0 fecha">
                    {{ setDateShortString(atenciondia.Fechahora) }}</v-col
                  >
                  <v-col class="ma-0 pa-0"
                    ><h3 color="primary" outlined class="tipo">
                      {{ atenciondia.Pago }}
                    </h3></v-col
                  >
                  <v-col class="ma-0 pa-0">
                    <p class="doc">{{ atenciondia.Nombredoc }}</p></v-col
                  >
                  <v-col class="ma-0 pa-0">
                    <v-btn
                      color="primary"
                      outlined
                      class="text-capitalize btn"
                      @click="btnSelectAtenciondiaByCliente(atenciondia)"
                      >Seleccionar atención</v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider class="mt-3"></v-divider>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <!-- Visitas en curso -->
      <v-card class="mb-5 ma-2 rounded-xl" :loading="activeLoadingAtenciondia">
        <!-- contenido -->
        <div class="pa-3">
          <!-- Título -->
          <v-card-title class="mt-4">
            <h2 class="ml-10 mb-5">Visitas del día</h2>
            <v-spacer></v-spacer>
            <!-- accion filtrar -->
            <div class="d-flex justify-lg-space-between justify-sm-start">
              <v-menu
                v-model="menuDateFilterAtencion"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFilterAtencion"
                    label="Filtrar por fecha"
                    prepend-icon="mdi-calendar"
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateFilterAtencion"
                  class="d-flex ma-auto"
                >
                  <v-row class="d-flex justify-end pa-2">
                    <v-btn
                      text
                      color="primary"
                      @click="btnFilterDateAtenciondia"
                      class="text-capitalize"
                    >
                      Filtrar
                    </v-btn>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </div>
          </v-card-title>
          <!-- Información de la tarjeta -->
          <div class="pa-3">
            <!-- Buscador -->
            <v-text-field
              class="mb-1"
              v-model="search"
              append-icon="mdi-magnify"
              color="primary"
              single-line
              autofocus
              hide-details
            ></v-text-field>
            <v-data-table
              :headers="headers"
              :items="atenciondias"
              :search="search"
              dense
              :footer-props="{
                'items-per-page-text': 'Filas por página',
              }"
            >
              <!-- Hora -->
              <template v-slot:item.Hora="{ item }">
                <div width="100%">
                  {{ setDateTimeString(item.Hora) }}
                </div>
              </template>
              <template v-slot:item.Nombre="{ item }">
                <div class="d-flex align-center">
                  <h3 dense color="primary" outlined class="my-3">
                    {{ item.Nombre }}
                  </h3>
                  <v-avatar
                    v-if="item.Monto == '&H80FF80'"
                    class="ml-3"
                    size="20"
                  >
                    <v-icon color="green">mdi-bookmark-check-outline</v-icon>
                  </v-avatar>
                </div>
              </template>
              <!-- Motivo -->
              <template v-slot:item.Pago="{ item }">
                <v-chip dense color="primary" outlined class="my-3">{{
                  setUpperString(item.Pago)
                }}</v-chip>
              </template>
              <!-- Acción -->
              <template v-slot:item.action="{ item }">
                <div class="actions">
                  <!-- Ver visita (redirige a vista Ficha médica ) -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="btnSelectFichaCliente(item.Fichan)"
                        v-bind="attrs"
                        v-on="on"
                        dense
                      >
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                    <span>Seleccionar</span>
                  </v-tooltip>
                </div>
              </template>
              <!-- Mensaje en caso de no haber datos para mostrar -->
              <template v-slot:no-data>
                <v-alert :value="true"> No hay resultados disponibles </v-alert>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
      <!-- contenido para el cliente seleccioando -->
      <div v-if="cliente.Nombre">
        <!-- Contenido principal -->
        <div v-if="!loadingClientes">
          <!-- Enviar un nuevo email -->

          <div v-if="activeShowSentEmail" ref="cardemail">
            <div v-if="cliente.Nombre">
              <v-card class="pa-3 mb-5 ma-2 rounded-xl">
                <v-card-title class="mt-4">
                  <h2 class="ml-10 mb-5">Enviar un correo</h2>

                  <v-spacer></v-spacer>
                  <v-btn icon @click="activeShowSentEmail = false"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="email.to"
                    outlined
                    label="Para"
                    :rules="[emailRule]"
                    required
                    dense
                    class="ma-2"
                  ></v-text-field>
                  <v-text-field
                    v-model="email.subject"
                    outlined
                    label="Asunto"
                    dense
                    class="ma-2"
                  ></v-text-field>
                  <v-textarea
                    v-model="email.body"
                    class="ma-2"
                    outlined
                    dense
                  ></v-textarea>
                  <div class="d-flex align-center justify-end">
                    <v-alert
                      type="info"
                      color="primary"
                      dense
                      class="mx-2 black--text"
                      outlined
                      >Solo se permite archivos tipo imagen, video y pdf o word,
                      no puede enviar archivos que superen el 1mb</v-alert
                    >
                    <div class="d-flex mt-3">
                      <v-file-input
                        v-model="filesemail"
                        color="primary"
                        dense
                        accept="image/*,video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        counter
                        label="Subir archivos"
                        multiple
                        placeholder="Seleccione los archivsp"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="primary"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </div>
                    <v-btn
                      class="ml-3"
                      color="primary"
                      :disabled="email.to == '' || email.body == ''"
                      @click="btnSentEmail"
                      :loading="activeUploadlingSentEmail"
                      >Enviar</v-btn
                    >
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <!-- alerta sin cliente seleccionado -->
            <div v-else class="d-flex justify-center pa-3 ma-3">
              <div class="d-flex flex-column">
                <v-avatar tile size="300">
                  <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
                </v-avatar>
                <div class="d-flex justify-center">
                  <h3 class="black_h3 mt-3 ml-3">
                    Debe seleccionar a un cliente primero
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <!-- Generación de certificados (componente) -->
          <div v-if="showGenerateCertificado" ref="cardcert">
            <generate-certificado></generate-certificado>
          </div>
          <!-- Ingreso de nuevo servicio (Vencimientos futuros) -->
          <div v-if="activeNewServis" class="pa-3" ref="cardservis">
            <v-card v-if="cliente.Nombre" class="pa-3 mb-5 rounded-xl">
              <v-card-title class="mt-4 ml-4 mb-5 d-flex justify-space-between">
                <h2 class="mt-4 ml-4">Nuevo servicio o producto</h2>
                <v-btn color="primary" icon @click="activeNewServis = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <!-- Formulario nuevo servicio -->
              <v-row>
                <!-- Tabla -->
                <v-col>
                  <div>
                    <v-card class="rounded-xl ma-2" elevation="0">
                      <v-card-title class="d-flex">
                        <v-text-field
                          v-model="searchProdus"
                          append-icon="mdi-magnify"
                          placeholder="Buscar servicio o producto"
                          single-line
                          hide-details
                        ></v-text-field>
                        <v-btn color="primary" class="mt-1 ml-2" @click="btnSearchProductByServiece" @keyup.enter="btnSearchProductByServiece">Buscar</v-btn>
                      </v-card-title>
                      <div class="pa-3">
                        <v-data-table
                          locale="ES-ES"
                          :headers="headersProdus"
                          :items="produs"
                          :search="searchProdus"
                          :loading="activeLoadingCargarProdu"
                          :footer-props="{
                            'items-per-page-text': 'Filas por página',
                          }"
                          dense
                        >
                          <template v-slot:item.action="{ item }">
                            <div class="actions">
                              <v-btn
                                class="ma-1"
                                color="primary"
                                icon
                                @click="btnSelectServis(item)"
                              >
                                <v-icon>mdi-check-circle-outline</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <template v-slot:no-data>
                            <v-alert :value="true">
                              No hay resultados disponibles
                            </v-alert>
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                  </div>
                </v-col>
                <v-divider vertical class="mt-3 mb-5"></v-divider>
                <!-- Detalles de la planificación -->
                <v-col class="mx-3">
                  <!-- Título y nombre de la mascota -->
                  <div class="mb-2">
                    <!-- Nombre de la mascota -->
                    <h3 v-if="cliente.Nombre" class="mb-3">
                      {{ cliente.Fichan }} | {{ cliente.Nombre }}
                    </h3>
                  </div>
                  <!-- Servicio seleccionado -->
                  <div v-if="selectServis" class="mb-2">
                    <v-card outlined color="primary" class="pa-3 mb-2">
                      <div class="white--text">{{ selectServis }}</div>
                    </v-card>
                  </div>
                  <!-- Fecha de la planificación -->
                  <div class="d-flex mt-2">
                    <!-- Fecha -->
                    <v-menu
                      v-model="menuDatePlanning"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="servi.Fecha"
                          label="Fecha de planificación"
                          prepend-icon="mdi-calendar"
                          readonly
                          dense
                          class="ma-2"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="servi.Fecha"
                        @input="menuDatePlanning = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-menu
                      ref="menu"
                      v-model="menuHourPlanning"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="hourPlanningServi"
                          label="Hora"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          dense
                          outlined
                          class="ma-2"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="servi.Fecha"
                        v-model="hourPlanningServi"
                        format="24hrs"
                        full-width
                        @click:minute="$refs.menu.save(hourPlanningServi)"
                      ></v-time-picker>
                    </v-menu>
                  </div>
                  <v-textarea
                    label="Observaciones"
                    outlined
                    class="ma-2"
                    dense
                    v-model="servi.Observaciones"
                  ></v-textarea>
                  <div class="d-flex justify-end mx-2">
                    <v-btn
                      color="primary"
                      class="rounded-lg text-capitalize mx-2"
                      @click="btnCreateNewServis('planning')"
                      :disabled="
                        !selectServis ||
                        !servi.Fecha ||
                        !hourPlanningServi ||
                        activeUploadingCreateService
                      "
                      :loading="activeUploadingCreateService"
                    >
                      Ingresar</v-btn
                    >
                    <v-btn
                      color="primary"
                      @click="btnCreateNewServis('apply')"
                      outlined
                      class="rounded-lg text-capitalize"
                      :disabled="
                        !selectServis ||
                        !servi.Fecha ||
                        !hourPlanningServi ||
                        activeUploadingCreateService
                      "
                      :loading="activeUploadingCreateService"
                    >
                      Aplicado</v-btn
                    >
                  </div>
                  <!-- Hisstorial de servicios realizados -->
                  <v-card width="100%" class="pa-3 mb-5 mt-5 rounded-xl">
                    <v-data-table
                      :headers="headersServisHistory"
                      :items="servisHistory"
                      :single-expand="singleExpand"
                      :expanded.sync="expanded"
                      item-key="Id"
                      show-expand
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <h2>Historial</h2>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td class="pa-3" :colspan="headers.length">
                          <div>
                            <div
                              v-if="item.Fechaentrega != '2001-01-01T00:00:00Z'"
                            >
                              Fecha de aplicación
                              {{
                                setDateShortString(
                                  item.Fechaentrega.substring(0, 10) +
                                    "T00:00:00.000Z"
                                )
                              }}
                              <div>Realizado por: {{ item.Quien }}</div>
                              <div>Observaciones: {{ item.Observaciones }}</div>
                            </div>
                            <h3 v-else>Pendiente por realizar</h3>
                          </div>
                          <div></div>
                        </td>
                      </template>
                      <template v-slot:item.Fecha="{ item }">
                        <div width="100%">
                          {{
                            setDateShortString(
                              item.Fecha.substring(0, 10) + "T00:00:00.000Z"
                            )
                          }}
                        </div>
                      </template>
                      <template v-slot:item.Tareacumplida="{ item }">
                        <v-chip
                          dense
                          outlined
                          small
                          color="primary"
                          class="ma-1"
                          >{{ item.Tareacumplida }}</v-chip
                        >
                      </template>
                      <template v-slot:item.action="{ item }">
                        <v-tooltip
                          v-if="item.Fechaentrega == '2001-01-01T00:00:00Z'"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="btnApplyServisById(item)"
                            >
                              <v-icon>mdi-check-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Dejar como realiado</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card>
                  <div></div>
                </v-col>
              </v-row>
            </v-card>
            <!-- Ícono + mensaje desplegado cuando se itenta visualizar información de una mascota sin antes haberla seleccionado-->
            <div v-else class="d-flex justify-center pa-3 ma-3">
              <div class="d-flex flex-column">
                <v-avatar tile size="300">
                  <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
                </v-avatar>
                <div class="d-flex justify-center">
                  <h3 class="mt-3 ml-3">
                    Debe seleccionar a un cliente primero
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <!-- Ingreso de nueva receta -->
          <div
            v-if="activeNewReceta"
            class="my-5 prescriptionContainer"
            id="newPrescription"
            ref="cardreceta"
          >
            <!-- contenido de la tarjeta -->
            <v-card v-if="cliente.Nombre" class="pa-3 mb-5 rounded-xl ma-2">
              <!-- titulo y acciones  -->
              <div class="d-flex pa-3 mb-5">
                <!-- titulo -->
                <div class="mt-4 ml-4">
                  <h2>Nueva receta</h2>
                </div>
                <v-spacer></v-spacer>
                <!-- boton de acciones de historial y cerrar -->
                <div class="mt-4 mr-4 d-flex">
                  <div>
                    <v-btn
                      v-if="activeViewHistoryReceta"
                      color="primary"
                      class="mr-1 rounded-lg text-capitalize"
                      outlined
                      @click="activeViewHistoryReceta = false"
                      >Cerrar historial</v-btn
                    >
                    <v-btn
                      v-else
                      color="primary"
                      class="mr-1 rounded-lg text-capitalize"
                      outlined
                      @click="activeViewHistoryReceta = true"
                      >Ver historial</v-btn
                    >
                  </div>
                  <v-btn
                    class="ml-3"
                    color="primary"
                    icon
                    @click="activeNewReceta = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
              <!-- contenido de la tarjeta -->
              <v-row class="pa-3">
                <!-- formulario receta -->
                <v-col>
                  <v-textarea
                    v-model="selectReceta"
                    outlined
                    auto-grow
                    placeholder="Receta"
                  >
                  </v-textarea>
                </v-col>
                <!-- historial y formato de impresión para generación -->
                <v-col md="4" lg="4">
                  <!-- Selección de formato y generación -->
                  <div class="d-flex flex-column align-center mt-3 mb-4">
                    <!-- verificación de logo y firma -->
                    <div class="d-flex">
                      <div>
                        <v-chip
                          color="primary"
                          class="ma-2 pa-2"
                          outlined
                          v-if="licen.Firma != 'n'"
                          small
                          >Firma
                          <div>
                            <v-icon class="pa-1"
                              >mdi-check-circle-outline</v-icon
                            >
                          </div></v-chip
                        >
                        <v-chip
                          v-else
                          color="primary"
                          outlined
                          class="ma-2 pa-2"
                          small
                          >Firma pendiente</v-chip
                        >
                      </div>
                      <div>
                        <v-chip
                          color="primary"
                          class="ma-2 pa-2"
                          v-if="logoUpload"
                          outlined
                          small
                          >Logo
                          <div>
                            <v-icon class="pa-1"
                              >mdi-check-circle-outline</v-icon
                            >
                          </div></v-chip
                        >
                        <v-chip
                          v-else
                          color="primary"
                          outlined
                          class="ma-2 pa-2"
                          small
                          >Logo pendiente</v-chip
                        >
                      </div>
                    </div>
                    <h3 class="mb-4 black_h3">
                      Seleccionar formato de impresión
                    </h3>

                    <!-- chip de seleccion de formato -->
                    <v-chip-group
                      active-class="red"
                      v-model="formartDoc"
                      class="d-flex"
                    >
                      <v-chip
                        class="mr-4"
                        outlined
                        color="primary"
                        value="a5"
                        @click=""
                      >
                        <h3>Recetario</h3>
                      </v-chip>
                      <v-chip
                        class="mr-4"
                        outlined
                        color="primary"
                        value="letter"
                        @click=""
                      >
                        <h3>Carta</h3>
                      </v-chip>
                      <v-chip
                        class="ml-2"
                        outlined
                        color="primary"
                        value="a4"
                        @click=""
                      >
                        <h3>Oficio</h3>
                      </v-chip>
                    </v-chip-group>
                    <!-- boton para cerar -->
                    <v-btn
                      color="primary"
                      :loading="uploadingCreateReceta"
                      :disabled="uploadingCreateReceta"
                      @click="btnGenerateReceta"
                      class="mt-5 text-capitalize rounded-lg"
                      >Crear e imprimir</v-btn
                    >
                  </div>
                  <!-- historial de recetas generadas -->
                  <div v-if="activeViewHistoryReceta" class="mt-3">
                    <div class="d-flex flex-column justify-center pa-1">
                      <v-data-table
                        locale="ES-ES"
                        :headers="headersRecetas"
                        :items="recetas"
                        :options="{
                          itemsPerPage: 5,
                        }"
                        :footer-props="{
                          'items-per-page-text': 'Filas por página',
                        }"
                        dense
                        :loading="activeUploadingChangueRecetas"
                      >
                        <template v-slot:item.Fecha="{ item }">
                          {{ setDateShortString(item.Fecha) }}
                        </template>
                        <template v-slot:item.action="{ item }">
                          <v-btn
                            dense
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="btnSelectReceta(item.Id)"
                          >
                            <v-icon class="black--text">mdi-eye-outline</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </div>
                    <v-spacer></v-spacer>
                    <!-- Ver detalle  -->
                    <div class="pr-5"></div>
                  </div>
                  <!-- Diálogo que se superpone al pinchar el ícono para "ver e imprimir" -->
                  <v-row justify="center">
                    <v-dialog
                      v-model="activeShowSelectReceta"
                      persistent
                      scrollable
                      max-width="550"
                    >
                      <v-card class="rounded-lg pa-3">
                        <div class="d-flex">
                          <div>
                            <v-card-title>
                              <h2>Receta de</h2>
                              <h3 class="pl-1">{{ cliente.Nombre }}</h3>
                            </v-card-title>
                          </div>
                          <v-spacer></v-spacer>
                          <div class="pt-2">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="btnHideSelectReceta"
                                >
                                  <v-icon
                                    color="primary"
                                    @click="btnHideSelectReceta"
                                    >mdi-close</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Cerrar</span>
                            </v-tooltip>
                          </div>
                        </div>
                        <v-card-text class="pa-3">
                          <div class="d-flex">
                            <strong>Fecha de emisión:</strong>
                            <p class="pl-1">
                              {{ setDateShortString(selectShowReceta.Fecha) }}
                            </p>
                          </div>
                          <div class="d-flex">
                            <strong> Profesional: </strong>
                            <p class="pl-1">
                              {{ selectShowReceta.Dr }}
                            </p>
                          </div>
                          <div class="d-flex">
                            <strong>Propietario(a):</strong>
                            <p class="pl-1">{{ cliente.Amo }}</p>
                          </div>
                          <div class="d-flex">
                            <strong>RUN:</strong>
                            <p class="pl-1">{{ cliente.Rut }}</p>
                          </div>
                          <div class="d-flex flex-column">
                            <strong>RP:</strong>
                            <v-card-text
                              class="pt-1"
                              v-html="setData"
                            ></v-card-text>
                          </div>
                        </v-card-text>
                        <v-card-action class="pa-3">
                          <div class="d-flex flex-column align-center">
                            <h3 class="pb-1 black_h3">
                              Seleccionar formato de impresión
                            </h3>
                            <!-- chip de seleccion de formato -->
                            <v-chip-group
                              active-class="red"
                              v-model="formartDoc"
                              class="d-flex pb-1"
                            >
                              <v-chip
                                class="mr-4"
                                outlined
                                color="primary"
                                value="a5"
                                @click=""
                              >
                                <h3>Recetario</h3>
                              </v-chip>
                              <v-chip
                                class="mr-4"
                                outlined
                                color="primary"
                                value="letter"
                                @click=""
                              >
                                <h3>Carta</h3>
                              </v-chip>
                              <v-chip
                                class="ml-2"
                                outlined
                                color="primary"
                                value="a4"
                                @click=""
                              >
                                <h3>Oficio</h3>
                              </v-chip>
                            </v-chip-group>
                            <v-btn
                              color="primary"
                              @click="btnGenerateSelectReceta"
                              class="text-capitalize"
                            >
                              Imprimir
                            </v-btn>
                          </div>
                        </v-card-action>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
            <!-- Ícono + mensaje desplegado cuando se intenta visualizar información de una mascota sin antes haberla seleccionado-->
            <div v-else class="d-flex justify-center pa-3 ma-3">
              <div class="d-flex flex-column">
                <v-avatar tile size="300">
                  <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
                </v-avatar>
                <div class="d-flex justify-center">
                  <h3 class="black_h3 mt-3 ml-3">
                    Debe seleccionar a un cliente primero
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <!-- Tarjeta de Gestión de archivos -->
          <div v-if="activeUploadFiles" ref="cardfile">
            <div v-if="cliente.Nombre">
              <v-row>
                <v-col>
                  <div class="pa-3">
                    <v-card class="pa-3 mb-5 rounded-xl">
                      <!-- titulo y acciones de la tarjeta -->
                      <div class="d-flex justify-space-between mx-5 mt-5">
                        <h3 v-if="cliente.Nombre">
                          {{ cliente.Fichan }} | {{ cliente.Nombre }}
                        </h3>
                        <v-btn
                          color="primary"
                          icon
                          @click="activeUploadFiles = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                      <!-- contenido de carga -->
                      <v-row class="pa-3 d-flex">
                        <!-- inputs de subida -->
                        <v-col col="12" cols="12" sm="12" md="12" lg="7">
                          <h2 class="ml-15 mb-5">Subir archivos</h2>
                          <h3>Cargar exámenes</h3>

                          <div class="d-flex mt-3">
                            <div class="">
                              <!-- input -->
                              <v-file-input
                                accept="docs/*"
                                label="Agrergar examenes"
                                multiple
                                outlined
                                full-width
                                counter
                                v-model="filesExamanes"
                                prepend-icon="mdi-file-chart-outline"
                                :show-size="1000"
                                class="border-edit ma-1"
                              >
                                <template v-slot:selection="{ index, text }">
                                  <v-avatar
                                    tile
                                    width="100%"
                                    class="d-flex flex-column ma-1"
                                  >
                                    <v-chip color="primary" dark label small>
                                      {{ text }}
                                    </v-chip>
                                  </v-avatar>
                                </template>
                              </v-file-input>
                            </div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="ml-2"
                                  color="primary"
                                  icon
                                  dense
                                  @click="btnSubmitExamenes"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-upload</v-icon>
                                </v-btn>
                              </template>
                              <span>Subir archivo</span>
                            </v-tooltip>
                          </div>
                          <v-divider class="mt-3 mb-3"></v-divider>
                          <h3>Cargar video</h3>
                          <div class="d-flex mt-3">
                            <div class="" width="100%">
                              <!-- input -->
                              <v-file-input
                                accept="video/*"
                                label="Agrergar videos"
                                multiple
                                outlined
                                full-width
                                counter
                                v-model="filesVideos"
                                prepend-icon="mdi-video-outline"
                                :show-size="1000"
                                class="border-edit ma-1"
                              >
                                <template v-slot:selection="{ index, text }">
                                  <v-avatar
                                    tile
                                    width="100%"
                                    class="d-flex flex-column ma-1"
                                  >
                                    <v-chip color="primary" dark label small>
                                      {{ text }}
                                    </v-chip>
                                  </v-avatar>
                                </template>
                              </v-file-input>
                            </div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="ml-2"
                                  color="primary"
                                  icon
                                  dense
                                  @click="btnSubmitVideos"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-upload</v-icon>
                                </v-btn>
                              </template>
                              <span>Subir archivo</span>
                            </v-tooltip>
                          </div>
                          <v-divider class="mt-3 mb-3"></v-divider>
                          <h3>Cargar imágenes</h3>
                          <div class="d-flex mt-3">
                            <div class="" width="100%">
                              <!-- input -->
                              <v-file-input
                                accept="image/*"
                                label="Agrergar imagenes"
                                multiple
                                outlined
                                full-width
                                counter
                                v-model="filesImagenes"
                                prepend-icon="mdi-file-image-outline"
                                :show-size="1000"
                                @change="addFilesImagenes"
                                class="border-edit ma-1"
                                height="100%"
                              >
                                <template v-slot:selection="{ index, text }">
                                  <v-avatar
                                    tile
                                    size="200"
                                    class="d-flex flex-column ma-1"
                                  >
                                    <img :src="getUrlImagenes(index)" />
                                    <v-chip color="primary" dark label small>
                                      {{ text }}
                                    </v-chip>
                                  </v-avatar>
                                </template>
                              </v-file-input>
                            </div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="ml-2"
                                  color="primary"
                                  icon
                                  dense
                                  @click="btnSubmitImagenes"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-upload</v-icon>
                                </v-btn>
                              </template>
                              <span>Subir archivo</span>
                            </v-tooltip>
                          </div>
                        </v-col>
                        <!-- directorio -->
                        <v-col col="12" cols="12" sm="12" md="12" lg="5">
                          <h2 class="mr-10 mb-5">Archivos subidos</h2>
                          <v-treeview
                            v-model="tree"
                            :open="initiallyOpen"
                            :items="directories"
                            activatable
                            item-key="name"
                            open-on-click
                          >
                            <template v-slot:prepend="{ item, open }">
                              <div class="d-flex">
                                <div v-if="item.path">
                                  <v-icon
                                    class="mr-2"
                                    v-if="licen.Var37 == 1"
                                    @click="btnDelete(item)"
                                    >mdi-delete-outline</v-icon
                                  >
                                </div>
                                <div class="d-flex">
                                  <v-icon
                                    class="mr-2"
                                    v-if="item.file"
                                    @click="btnDownload(item)"
                                    >mdi-download</v-icon
                                  >
                                  <v-icon v-if="!item.file">
                                    {{
                                      open ? "mdi-folder-open" : "mdi-folder"
                                    }}
                                  </v-icon>
                                  <v-icon v-else>
                                    {{ files[item.file] }}
                                  </v-icon>
                                </div>
                              </div>
                            </template>
                          </v-treeview>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </div>
            <!-- Ícono + mensaje desplegado cuando se itenta visualizar información de una mascota sin antes haberla seleccionado-->
            <div v-else class="d-flex justify-center pa-3 ma-3">
              <div class="d-flex flex-column">
                <v-avatar tile size="300">
                  <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
                </v-avatar>
                <div class="d-flex justify-center">
                  <h3 class="mt-3 ml-3">
                    Debe seleccionar a un cliente primero
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <v-overlay :absolute="absolute" v-model="loadingUpload" opacity="0">
            <v-progress-circular
              style="z-index: 20001"
              indeterminate
              :size="200"
              color="primary"
              class=""
              title="Imagen"
            >
              Cargando</v-progress-circular
            >
          </v-overlay>

          <!-- Cliente -->
          <v-row class="mb-8" ref="atencionref">
            <!-- Propietario -->
            <v-col cols="12" xs="12" md="12" lg="6" xl="6">
              <div class="pa-3">
                <h2 class="ma-2 mb-5" ref="centralViewAtencion">
                  Datos Propietario
                </h2>
                <div>
                  <v-text-field
                    v-model="cliente.Amo"
                    readonly
                    outlined
                    label="Nombre"
                    dense
                    class="ma-2"
                  ></v-text-field>
                </div>
                <div
                  class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                >
                  <v-text-field
                    v-model="cliente.Direccion"
                    label="Dirección"
                    outlined
                    dense
                    readonly
                    class="ma-2"
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="cliente.Comuna"
                    label="Comuna"
                    outlined
                    dense
                    readonly
                    class="ma-2"
                  ></v-text-field>
                  <v-text-field
                    v-model="cliente.Ciudad"
                    label="Ciudad"
                    outlined
                    readonly
                    dense
                    class="ma-2"
                  ></v-text-field>
                </div>
                <div
                  class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                >
                  <v-text-field
                    v-model="cliente.Fono"
                    label="Teléfono"
                    outlined
                    dense
                    readonly
                    class="ma-2"
                  ></v-text-field>
                  <v-text-field
                    v-model="cliente.Altura"
                    label="Celular"
                    outlined
                    dense
                    readonly
                    class="ma-2"
                  >
                  </v-text-field>
                </div>
                <div
                  class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                >
                  <v-text-field
                    outlined
                    readonly
                    dense
                    v-model="cliente.Email"
                    label="Email"
                    class="ma-2"
                  ></v-text-field>
                  <v-text-field
                    v-model="cliente.Edad"
                    label="Plan de salud"
                    outlined
                    readonly
                    dense
                    class="ma-2"
                  >
                  </v-text-field>
                </div>
              </div>
            </v-col>
            <v-divider
              vertical
              class="my-8 d-none d-sm-none d-md-none d-lg-flex d-xl-flex"
            ></v-divider>
            <!-- Mascota -->
            <v-col cols="12" xs="12" md="12" lg="6" xl="6">
              <div class="pa-3">
                <h2 class="ma-2 mb-5">Datos Mascota</h2>
                <div
                  class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                >
                  <v-text-field
                    v-model="cliente.Nombre"
                    outlined
                    readonly
                    label="Nombre"
                    dense
                    class="ma-2"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    label="N° de ficha"
                    v-model="cliente.Fichan"
                    readonly
                    dense
                    class="ma-2"
                  >
                  </v-text-field>
                </div>
                <div
                  class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                >
                  <v-text-field
                    outlined
                    dense
                    readonly
                    label="Edad"
                    :value="getAge(cliente.Nacimiento)"
                    class="ma-2"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    label="Fecha de nacimiento"
                    :value="setDateNacimiento(cliente.Nacimiento)"
                    dense
                    readonly
                    class="ma-2"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="cliente.Sexo"
                    label="Género"
                    class="ma-2"
                  ></v-text-field>
                </div>
                <div
                  class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                >
                  <v-text-field
                    outlined
                    v-model="cliente.Raza"
                    label="Raza"
                    readonly
                    dense
                    class="ma-2"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="cliente.Esp"
                    label="Especie"
                    readonly
                    class="ma-2"
                  ></v-text-field>
                </div>
                <div
                  class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                >
                  <v-text-field
                    outlined
                    dense
                    v-model="cliente.Microchip"
                    label="N° de chip"
                    readonly
                    class="ma-2"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    readonly
                    :value="cliente.Color"
                    dense
                    label="Color"
                    class="ma-2"
                  ></v-text-field>
                </div>
                <!-- <div class="d-flex">
              <div class="mt-2 mr-2">Crear nuevo</div>
              <v-btn outlined color="primary" class="ma-1">Procedimiento</v-btn>
              <v-btn outlined color="primary" class="ma-1">Insumo</v-btn>
              <v-btn outlined color="primary" class="ma-1">Examen</v-btn>
              <v-btn outlined color="primary" class="ma-1">Control</v-btn>
            </div> -->
              </div>
            </v-col>
          </v-row>
          <!-- Ingreso de checklist -->
          <div ref="cardchecklist" v-if="activeShowChecklist">
            <v-card
              v-if="cliente.Nombre"
              width="100%"
              class="pa-3 mb-5 rounded-xl"
            >
              <div class="pa-3">
                <!-- Título y botón cerrar checklist -->
                <div id="checklist" class="d-flex mt-4 ml-4 mb-5">
                  <div class="d-flex">
                    <h2>Checklist</h2>
                    <h3 v-if="cliente.Nombre" class="ml-2">
                      {{ cliente.Fichan }} | {{ cliente.Nombre }}
                    </h3>
                  </div>
                  <v-spacer></v-spacer>
                  <div>
                    <v-btn
                      icon
                      color="primary"
                      @click="activeShowChecklist = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
                <!-- Formulario checklist -->
                <v-row class="pa-3">
                  <!-- Columna izquierda -->
                  <v-col cols="6">
                    <!-- Primera fila -->
                    <div class="d-flex">
                      <v-select
                        outlined
                        dense
                        v-model="checklist.Peso"
                        :items="pesoOptions"
                        label="Peso"
                        class="ma-1"
                      >
                      </v-select>
                      <v-select
                        outlined
                        dense
                        v-model="checklist.Cc"
                        :items="ccOptions"
                        label="C. Corporal"
                        class="ma-1"
                      >
                      </v-select>
                      <v-text-field
                        outlined
                        dense
                        v-model="diagnostico.Peso"
                        readonly
                        label="Peso actual"
                        class="ma-1"
                      >
                      </v-text-field>
                    </div>
                    <!-- Segunda fila pelaje -->
                    <div class="d-flex align-center">
                      <v-col class="pa-0 ma-0" lg="4" md="4" xl="4">
                        <v-select
                          outlined
                          dense
                          label="Pelaje"
                          :items="pelajeOptions"
                          v-model="checklist.Pelaje"
                          class="ma-1"
                        ></v-select>
                      </v-col>
                      <v-textarea
                        v-model="checklist.Pelobs"
                        dense
                        counter
                        outlined
                        rows="2"
                        class="ma-1"
                        label="Observaciones del pelaje"
                      >
                      </v-textarea>
                    </div>
                    <!-- Tercera fila Odios -->
                    <div class="d-flex align-center">
                      <v-col lg="4" md="4" xl="4" class="ma-0 pa-0">
                        <v-select
                          outlined
                          v-model="checklist.Orejas"
                          dense
                          :items="oidosOptions"
                          label="Oidos"
                          class="ma-1"
                        >
                        </v-select>
                      </v-col>
                      <v-textarea
                        v-model="checklist.Oreobs"
                        dense
                        counter
                        outlined
                        rows="2"
                        class="ma-1"
                        label="Observaciones de los oidos"
                      >
                      </v-textarea>
                    </div>
                    <!-- Cuarta fila ojos -->
                    <div class="d-flex align-center">
                      <v-col lg="4" md="4" xl="4" class="ma-0 pa-0">
                        <v-select
                          v-model="checklist.Ojos"
                          dense
                          :items="ojosOptions"
                          outlined
                          label="Ojos"
                          class="ma-1"
                        >
                        </v-select>
                      </v-col>
                      <v-textarea
                        counter
                        v-model="checklist.Ojoobs"
                        dense
                        outlined
                        rows="2"
                        class="ma-1"
                        label="Observaciones de los ojos"
                      >
                      </v-textarea>
                    </div>
                    <!-- Quinta fila mucosas -->
                    <div class="d-flex align-center">
                      <v-col lg="4" md="4" xl="4" class="ma-0 pa-0">
                        <v-select
                          outlined
                          label="Mucosas"
                          dense
                          v-model="checklist.Nariz"
                          :items="mucosasOptions"
                          class="ma-1"
                        >
                        </v-select>
                      </v-col>
                      <v-textarea
                        counter
                        dense
                        outlined
                        v-model="checklist.Narobs"
                        rows="2"
                        class="ma-1"
                        label="Observaciones de la mucosa"
                      >
                      </v-textarea>
                    </div>
                    <!-- Sexta fila cavidad -->
                    <div class="d-flex align-center">
                      <v-col lg="4" md="4" xl="4" class="ma-0 pa-0">
                        <v-select
                          outlined
                          dense
                          v-model="checklist.Garganta"
                          :items="cavidadoralOptions"
                          label="Cavidad oral"
                          class="ma-1"
                        ></v-select>
                      </v-col>
                      <v-textarea
                        counter
                        dense
                        outlined
                        v-model="checklist.Garobs"
                        class="ma-1"
                        rows="2"
                        label="Observaciones de la cavidad oral"
                      ></v-textarea>
                    </div>
                    <!-- Séptima fila boca -->
                    <div class="d-flex align-center">
                      <v-col lg="4" md="4" xl="4" class="ma-0 pa-0">
                        <v-select
                          outlined
                          v-model="checklist.Boca"
                          dense
                          :items="abdomenOptions"
                          label="Abdomen / Torax"
                          class="ma-1"
                        >
                        </v-select>
                      </v-col>
                      <v-textarea
                        v-model="checklist.Bocobs"
                        dense
                        counter
                        class="ma-1"
                        outlined
                        rows="2"
                        label="Observaciones de la abdomen y torax"
                      ></v-textarea>
                    </div>
                    <!-- Octava fila extremidades -->
                    <div class="d-flex align-center">
                      <v-col lg="4" md="4" xl="4" class="ma-0 pa-0">
                        <v-select
                          outlined
                          v-model="checklist.Extremidades"
                          dense
                          :items="extremidadesOptions"
                          label="Extremidades"
                          class="ma-1"
                        ></v-select>
                      </v-col>
                      <v-textarea
                        v-model="checklist.Extobs"
                        dense
                        counter
                        outlined
                        rows="2"
                        class="ma-1"
                        label="Observaciones de las extremidades"
                      ></v-textarea>
                    </div>
                  </v-col>
                  <v-divider vertical class="mt-4 mb-4"></v-divider>
                  <!-- Columna derecha -->
                  <v-col cols="6">
                    <h3 class="h2 black_h3 ml-4">Estado general</h3>
                    <div class="d-flex mt-3">
                      <v-select
                        v-model="checklist.Diag"
                        class="ma-2"
                        :items="diagOptions"
                        outlined
                        dense
                        label="Estado"
                      >
                      </v-select>
                    </div>
                    <!-- requiere control -->
                    <div class="d-flex">
                      <v-checkbox
                        v-model="requireControl"
                        dense
                        label="Requiere control"
                        class="ma-2"
                      ></v-checkbox>
                      <v-menu
                        v-model="dateControlChecklist"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateChecklist"
                            dense
                            label="Fecha control"
                            outlined
                            prepend-icon="mdi-calendar"
                            readonly
                            class="ma-2"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dateChecklist"
                          @input="dateControlChecklist = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                    <!-- servis checklist selccionado -->
                    <div v-if="selectServisChecklist" class="mb-4">
                      <v-card
                        outlined
                        color="primary"
                        class="pa-3 mb-2 d-flex align-center"
                      >
                        <div class="white--text">
                          {{ selectServisChecklist }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          color="white"
                          @click="selectServisChecklist = ''"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card>
                    </div>
                    <div v-if="requireControl">
                      <!-- lista de servicios -->

                      <v-card class="pa-3 mb-5 rounded-xl ma-2">
                        <v-card-title class="d-flex">
                          <v-text-field
                            v-model="searchProdus"
                            append-icon="mdi-magnify"
                            placeholder="Buscar servicio o producto"
                            single-line
                            hide-details
                          ></v-text-field>
                          <v-btn color="primary" class="mt-1 ml-2" @click="btnSearchProductByServiece" @keyup.enter="btnSearchProductByServiece" >Buscar</v-btn>
                        </v-card-title>
                        <div class="pa-3">
                          <v-data-table
                            locale="ES-ES"
                            :headers="headersProdus"
                            :items="produs"
                            :loading="activeLoadingCargarProdu"
                            :search="searchProdus"
                            :footer-props="{
                              'items-per-page-text': 'Filas por página',
                            }"
                            dense
                          >
                            <template v-slot:item.action="{ item }">
                              <div class="actions">
                                <v-btn
                                  class="ma-1"
                                  color="primary"
                                  icon
                                  @click="btnSelectServisChecklist(item)"
                                >
                                  <v-icon>mdi-check-circle-outline</v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <template v-slot:no-data>
                              <v-alert :value="true">
                                No hay resultados disponibles
                              </v-alert>
                            </template>
                          </v-data-table>
                        </div>
                      </v-card>
                      <div>
                        <v-textarea
                          v-model="checklist.Solobs"
                          rows="3"
                          class="ma-2"
                          counter
                          outlined
                          label="Observaciones para la solicitud especial"
                        ></v-textarea>
                      </div>
                    </div>
                    <div class="d-flex justify-end">
                      <v-btn
                        class="rounded-lg text-capitalize ma-2"
                        color="primary"
                        @click="btnUpdateChecklist"
                        >Grabar
                      </v-btn>
                    </div>
                    <v-divider class="mt-4 mb-4"></v-divider>
                    <div v-if="activeShowHistorialChecklist">
                      <h3 class="black_h3 mb-4 mt-1 ml-4">
                        Historial de checklist
                      </h3>
                      <v-card
                        class="d-flex justify-space-between pa-2 mb-2"
                        elevation="1"
                        outlined
                        v-for="checklist in checklists"
                        :key="checklist.id"
                      >
                        <div class="mt-2">
                          {{ setDateLargeString(checklist.Fecha) }} | Dr:
                          Fernando Clave
                        </div>
                        <div>
                          <div>
                            <v-chip outlined small color="primary">{{
                              checklist.Diag
                            }}</v-chip>
                            <v-btn icon>
                              <v-icon>mdi-arrow-right</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <!-- Ícono + mensaje desplegado cuando se intenta visualizar información de una mascota sin antes haberla seleccionado-->
            <div v-else class="d-flex justify-center pa-3 ma-3">
              <div class="d-flex flex-column">
                <v-avatar tile size="300">
                  <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
                </v-avatar>
                <div class="d-flex justify-center">
                  <h3 class="mt-3 ml-3">
                    Debe seleccionar a un cliente primero
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <!--formulario de atención -->
          <v-row ref="atenciondiaview">
            <!-- Formulario de atención -->
            <v-col cols="12">
              <div class="pa-3">
                <!-- Titulo y acciones -->
                <div class="d-flex align-center mb-3">
                  <h2 class="ma-3">Atención</h2>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    outlined
                    class="rounded-lg text-capitalize ma-3"
                    @click="activeViewHistoryAtenciodia = true"
                    >Historial de atenciones</v-btn
                  >
                </div>

                <!-- Panel desplegado al pinchar botón "Historial de atenciones" -->
                <div v-if="activeViewHistoryAtenciodia">
                  <!-- Historial de atenciones -->

                  <v-card class="pa-3 rounded-xl ma-2 my-3">
                    <v-row justify="center" class="ma-0 pa-0 mb-2">
                      <div class="d-flex pa-3 justify-center" width="100%">
                        <h2 class="mb-4 mt-1">Historial de atenciones</h2>
                        <v-btn
                          color="primary"
                          icon
                          class="mx-2"
                          @click="
                            activeShowPrinterHistorialAtencionesdia = true
                          "
                        >
                          <v-icon>mdi-printer-outline</v-icon>
                        </v-btn>
                        <v-btn
                          color="primary"
                          icon
                          @click="activeViewHistoryAtenciodia = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </v-row>
                    <div
                      class="d-flex pa-3 justify-center"
                      v-if="activeShowPrinterHistorialAtencionesdia"
                    >
                      <ImprimirHistorialAtencionesdia />
                    </div>
                    <v-card-title>
                      <v-text-field
                        v-model="searchAtenciondiaByCliente"
                        append-icon="mdi-magnify"
                        placeholder="Buscar atención"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <div class="pa-3">
                      <v-data-table
                        locale="ES-ES"
                        :headers="headersAtenciondiaByCliente"
                        :items="atenciondiasByCliente"
                        :search="searchAtenciondiaByCliente"
                        :footer-props="{
                          'items-per-page-text': 'Filas por página',
                        }"
                        :single-expand="singleExpandAtencion"
                        :expandedAtencion.sync="expandedAtencion"
                        item-key="Id"
                        show-expand
                      >
                        <template v-slot:item.Fecha="{ item }">
                          <div class="actions">
                            {{ setDateStringTimeZoneShort(item.Fecha) }}
                          </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                          <div class="actions"></div>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                          <td class="pa-3" :colspan="headers.length">
                            <div class="pa-3">
                              <div class="d-flex">
                                <h2>Diagnóstico</h2>
                              </div>
                              <div class="ma-0 pa-0 mt-3">
                                <v-row class="d-flex">
                                  <v-col class="d-flex">
                                    <h2>T°:</h2>
                                    <p class="ml-2 mt-1">
                                      {{ item.Diagnostico.Temperatura }}
                                    </p>
                                  </v-col>
                                  <v-col class="d-flex">
                                    <h2>Peso:</h2>
                                    <p class="ml-2 mt-1">
                                      {{ item.Diagnostico.Peso }}
                                    </p>
                                  </v-col>
                                  <v-col class="d-flex">
                                    <h2>
                                      <h2>Fr.car:</h2>

                                      <p class="ml-2 mt-1">
                                        {{ item.Diagnostico.Frecar }}
                                      </p>
                                    </h2>
                                  </v-col>
                                  <v-col class="d-flex">
                                    <h2>Fr.res:</h2>
                                    <p class="ml-2 mt-1">
                                      {{ item.Diagnostico.Freres }}
                                    </p>
                                  </v-col>
                                </v-row>
                                <div class="mt-1">
                                  <v-row class="d-flex">
                                    <v-col>
                                      <strong>Anamnesis:</strong>
                                      <p class="mt-1">
                                        {{ item.Informe }}
                                      </p>
                                    </v-col>
                                  </v-row>
                                </div>
                                <div class="mt-2">
                                  <v-row class="d-flex">
                                    <v-col>
                                      <strong>Examen inicial:</strong>
                                      <p class="mt-1">
                                        {{ item.Diagnostico.Otros }}
                                      </p>
                                    </v-col>
                                    <v-col>
                                      <strong>Prediagnóstico:</strong>
                                      <p>
                                        {{ item.Diagnostico.Prediagnostico }}
                                      </p>
                                    </v-col>
                                  </v-row>
                                </div>
                                <div class="mt-2">
                                  <v-row class="d-flex">
                                    <v-col>
                                      <strong>Diagnóstico:</strong>
                                      <p>
                                        {{ item.Diagnostico.Diagnostico }}
                                      </p>
                                    </v-col>
                                    <v-col>
                                      <strong>Tratamiento:</strong>
                                      <p>
                                        {{ item.Diagnostico.Tratamiento }}
                                      </p>
                                    </v-col>
                                  </v-row>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </td>
                        </template>
                        <template v-slot:no-data>
                          <v-alert :value="true">
                            No hay resultados disponibles
                          </v-alert>
                        </template>
                      </v-data-table>
                    </div>
                  </v-card>
                </div>
                <!-- Cuerpo del formulario -->

                <div>
                  <div
                    class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                  >
                    <div v-if="licen.Var37 == 1">
                      <v-autocomplete
                        v-model="atenciondia.Nombredoc"
                        :items="doctors"
                        item-text="Nombre"
                        atenciondia.Nombredoc
                        outlined
                        dense
                        label="Profesional"
                        class="ma-2"
                      ></v-autocomplete>
                    </div>
                    <div v-else>
                      <v-text-field
                        v-model="atenciondia.Nombredoc"
                        outlined
                        readonly
                        dense
                        label="Profesional"
                        class="ma-2"
                      ></v-text-field>
                    </div>
                    <v-text-field
                      outlined
                      dense
                      v-model="diagnostico.Temperatura"
                      type="number"
                      label="T°"
                      class="ma-2"
                    >
                    </v-text-field>
                    <v-text-field
                      outlined
                      dense
                      v-model="diagnostico.Peso"
                      type="number"
                      label="Peso"
                      class="ma-2"
                    >
                    </v-text-field>
                    <v-text-field
                      outlined
                      dense
                      v-model="diagnostico.Frecar"
                      type="number"
                      label="Fr. Card."
                      class="ma-2"
                    >
                    </v-text-field>
                    <v-text-field
                      outlined
                      dense
                      label="Fr. Resp."
                      v-model="diagnostico.Freres"
                      type="number"
                      class="ma-2"
                    >
                    </v-text-field>
                  </div>
                  <!-- central -->
                  <div>
                    <div>
                      <v-textarea
                        name="input-7-1"
                        outlined
                        v-model="atenciondia.Informe"
                        label="Anamnesis"
                        auto-grow
                        class="ma-2"
                      ></v-textarea>
                    </div>
                    <div
                      class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                    >
                      <v-textarea
                        name="input-7-1"
                        outlined
                        v-model="diagnostico.Otros"
                        label="Examen inicial"
                        auto-grow
                        class="ma-2"
                      ></v-textarea>
                      <v-textarea
                        name="input-7-1"
                        outlined
                        v-model="diagnostico.Prediagnostico"
                        label="Pre diagnóstico"
                        auto-grow
                        class="ma-2"
                      ></v-textarea>
                    </div>
                    <div
                      class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                    >
                      <v-textarea
                        name="input-7-1"
                        outlined
                        label="Diagnóstico"
                        v-model="diagnostico.Diagnostico"
                        auto-grow
                        class="ma-2"
                      ></v-textarea>
                      <v-textarea
                        name="input-7-1"
                        outlined
                        v-model="diagnostico.Tratamiento"
                        label="Tratamiento"
                        auto-grow
                        class="ma-2"
                      ></v-textarea>
                    </div>
                  </div>
                  <!-- patologia -->
                  <div class="d-flex">
                    <v-autocomplete
                      outlined
                      v-model="diagnostico.Categoria"
                      label="Patología"
                      :items="fames"
                      item-text="Descripcion"
                      item-value="Descripcion"
                      class="ma-2"
                    ></v-autocomplete>
                  </div>
                </div>
                <!-- Botones de acción -->
                <div class="d-flex justify-end">
                  <div v-if="validatedSaveDiagnosticos">
                    <v-btn
                      :disabled="uploadingCreateDiagnostico"
                      :loading="uploadingCreateDiagnostico"
                      class="mr-2 text-capitalize rounded-lg"
                      @click="updateDiagnostico"
                      color="primary"
                      >Grabar</v-btn
                    >
                  </div>
                  <div v-else>
                    <v-alert color="red" outlined
                      >No pueden modificar un registro anterior</v-alert
                    >
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- Notificación actualización diagnóstico -->
          <v-snackbar
            color="primary"
            v-model="successCreateDiagnostico"
            class="text-center"
          >
            Diagnostico actualizado con éxito
          </v-snackbar>
          <v-snackbar
            color="primary"
            v-model="successDeleteFile"
            class="text-center"
          >
            Archivo eliminado éxito
          </v-snackbar>
          <v-snackbar
            color="primary"
            v-model="activeCreateServisEntregado"
            class="text-center"
          >
            Servicios entregado con éxito
          </v-snackbar>
          <!-- Noti actualización checklist -->
          <v-snackbar
            color="primary"
            v-model="successCreateChecklist"
            multiLine
          >
            Checklist actualizado con éxito
          </v-snackbar>
          <!-- Noti creación de servicio-->
          <v-snackbar
            color="primary"
            v-model="successCreateService"
            multiLine
          >
            Servicio creado con éxito
          </v-snackbar>
          <!-- Noti creación de receta-->
          <v-snackbar
            color="primary"
            v-model="successCreateReceta"
            multiLine
          >
            Receta creada con éxito
          </v-snackbar>
          <!-- Email enviado-->
          <v-snackbar
            color="primary"
            v-model="successSentEmail"
            multiLine
          >
            Email enviado con éxito
          </v-snackbar>
        </div>
        <!-- Loading bar -->
        <div v-else>
          <v-overlay :absolute="absolute" opacity="0">
            <v-progress-circular
              style="z-index: 20001"
              indeterminate
              :size="200"
              color="primary"
              class=""
              title="Imagen"
            >
              Cargando</v-progress-circular
            >
          </v-overlay>
        </div>
        <!-- loading bar cargando archivo -->
        <div v-if="activeUploadFileOver">
          <v-overlay :absolute="absolute" opacity="0">
            <v-progress-circular
              style="z-index: 20001"
              indeterminate
              :size="200"
              color="primary"
              class=""
              title="Imagen"
            >
              Subiendo</v-progress-circular
            >
          </v-overlay>
        </div>
        <!-- Generación de Receta -->
        <div>
          <!-- Formato recetario -->
          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="receta"
            :pdf-quality="5"
            :manual-pagination="false"
            :pdf-format="formartDoc"
            pdf-orientation="portrait"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
          >
            <!-- Clase principal -->
            <section slot="pdf-content">
              <!--  Contenido-->
              <!-- Formato A4 (oficio)-->
              <div
                v-if="formatA4 || formatLetter"
                v-bind:class="{
                  formatLetter: true,
                  contentrecetaLetter: 'contentrecetaA4',
                }"
              >
                <!-- Encabezado -->
                <div class="headerA4">
                  <div class="containt_headerA4">
                    <strong class="main_titleA4"
                      >Receta médica electrónica</strong
                    >
                    <div class="containt_logoA4">
                      <img class="logoA4" :src="logoUpload" height="50px" />
                    </div>
                  </div>
                </div>
                <!-- Subtítulo-->
                <div class="subtitleA4">
                  <!-- Primera columna (datos receta) -->
                  <div class="firstColumnSubtitle">
                    <div class="id_containerA4">
                      <strong>ID:</strong>
                      <p class="idA4">{{ docId }}</p>
                    </div>
                    <div class="date_containerA4">
                      <strong>Fecha de emisión:</strong>
                      <p class="fechaA4">{{ setDateShortString(today) }}</p>
                    </div>
                    <div class="amo_containerA4">
                      <strong>Propietario(a):</strong>
                      <div class="amoA4">{{ cliente.Amo }}</div>
                    </div>
                    <div class="run_containerA4">
                      <strong>RUN:</strong>
                      <div class="runA4">{{ cliente.Rut }}</div>
                    </div>
                  </div>
                  <!-- Segunda columna (información empresa) -->
                  <div class="secondColumnSubtitle">
                    <div class="logoContainer">
                      <div class="companyData">
                        <div>{{ clinica.Nombre }}</div>
                        <div>
                          {{ clinica.Direccion }} , {{ clinica.Ciudad }}
                        </div>
                        <div>{{ clinica.Fono }}</div>
                        <div>{{ clinica.Email }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Línea divisoria -->
                <hr />
                <!-- Datos paciente -->
                <div class="customersectionA4">
                  <div class="first_row_mascota_dato">
                    <div class="columnA4bigger">
                      <strong>Ficha / Paciente</strong>
                      <p class="strong_title">
                        {{ cliente.Fichan }} - {{ cliente.Nombre }}
                      </p>
                    </div>
                    <div class="columnA4">
                      <strong>Microchip</strong>
                      <p>
                        {{ cliente.Microchip }}
                      </p>
                    </div>
                    <div class="columnA4">
                      <strong>Edad / sexo</strong>
                      <p>{{ getAge(cliente.Nacimiento) }}</p>
                      <p class="colInformation">{{ cliente.Sexo }}</p>
                    </div>
                  </div>
                  <div class="second_row_mascota_dato">
                    <div class="columnA4_second_row">
                      <strong>Especie / Raza</strong>
                      <p>
                        {{ cliente.Esp }}
                        {{ cliente.Raza }}
                      </p>
                    </div>
                    <div class="columnA4_second_row_address">
                      <strong>Dirección</strong>
                      <p class="colInformation">
                        {{ cliente.Direccion }}
                        {{ cliente.Ciudad }}
                        {{ cliente.Comuna }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Línea divisoria -->
                <hr />
                <!-- Prescripción -->
                <div class="descriptionRecetaA4">
                  <strong>Prescripción</strong>
                  <p v-html="setData"></p>
                </div>
                <!-- Línea divisoria -->
                <hr />
                <!-- Datos médico tratante -->
                <div class="dr_data">
                  <div class="icon">
                    <img
                      src="/icons/vet_symbol.svg"
                      width="50px"
                      height="50px"
                    />
                  </div>
                  <div class="contenedor-firma">
                    <img :src="licen.Firma" height="100px" />
                    <div class="texto-encina">
                      <strong>Dr. {{ atenciondia.Nombredoc }}</strong>
                      <p>RUN {{ atenciondia.Rutdoc }}</p>
                    </div>
                  </div>
                </div>
                <hr />
                <!-- Pie -->
                <div class="footer-verifyA4">
                  <div class="first_column_footer">
                    <strong
                      >Verifique este documento escaneando el código qr</strong
                    >
                    <div class="verify_document">
                      <p>https://dash.clivenapp.cl/verify-doc/{{ docId }}</p>
                    </div>
                  </div>
                  <div>
                    <qrcode
                      :value="`https://dash.clivenapp.cl/verify-doc/${docId}`"
                      :options="{ width: 90 }"
                    ></qrcode>
                  </div>
                </div>
              </div>
              <!-- Formato A5 (recetario)-->
              <div v-if="formatA5" class="contentrecetaA5">
                <!-- Encabezado -->
                <div class="headerA5">
                  <div class="d-flex">
                    <strong class="main_titleA5"
                      >Receta médica electrónica</strong
                    >
                  </div>
                </div>
                <!-- Subtítulo (id, fecha) -->
                <div class="subtitleA5">
                  <!-- Primera columna (datos receta) -->
                  <div class="firstColumnSubtitleA5">
                    <div class="id_containerA5">
                      <strong>ID:</strong>
                      <p class="id">{{ docId }}</p>
                    </div>
                    <div class="date_containerA5">
                      <strong>Fecha de emisión:</strong>
                      <p class="fecha">{{ setDateShortString(today) }}</p>
                    </div>
                    <div class="amo_containerA5">
                      <strong>Propietario(a):</strong>
                      <p class="amoA5">{{ cliente.Amo }}</p>
                    </div>
                    <div class="run_containerA5">
                      <strong>RUN:</strong>
                      <p class="runA5">{{ cliente.Rut }}</p>
                    </div>
                  </div>
                  <!-- Segunda columna (logo + información empresa) -->
                  <div class="secondColumnSubtitleA5">
                    <div class="logoContainerA5">
                      <img
                        class="logoA5"
                        :src="logoUpload"
                        width="45px"
                        height="45px"
                      />
                      <div class="companyData">
                        <div>{{ clinica.Nombre }}</div>
                        <div>
                          {{ clinica.Direccion }} , {{ clinica.Ciudad }}
                        </div>
                        <div>{{ clinica.Fono }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Línea divisoria -->
                <hr />
                <!-- Datos paciente -->
                <div class="customersectionA5">
                  <div class="first_row_mascota_datoA5">
                    <div class="columnA5">
                      <strong>Ficha / Paciente</strong>
                      <p class="strong_title">
                        {{ cliente.Fichan }} - {{ cliente.Nombre }}
                      </p>
                    </div>
                    <div class="columnA5lesswWidth">
                      <strong>Microchip</strong>
                      <p>
                        {{ cliente.Microchip }}
                      </p>
                    </div>
                    <div class="columnA5">
                      <strong>Edad</strong>
                      <p>{{ getAge(cliente.Nacimiento) }}</p>
                      <p class="colInformation">{{ cliente.Sexo }}</p>
                    </div>
                  </div>
                  <div class="second_row_mascota_dato">
                    <div class="columnA4_second_row">
                      <strong>Especie / Raza</strong>
                      <p>
                        {{ cliente.Esp }}
                        {{ cliente.Raza }}
                      </p>
                    </div>
                    <div class="columnA4_second_row_address">
                      <strong>Dirección</strong>
                      <p class="colInformation">
                        {{ cliente.Direccion }}
                        {{ cliente.Ciudad }}
                        {{ cliente.Comuna }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Línea divisoria -->
                <hr class="lineA5" />
                <!-- Prescripción -->
                <div class="descriptionRecetaA5">
                  <strong>Prescripción</strong>
                  <p v-html="setData"></p>
                </div>
                <!-- Línea divisoria -->
                <hr />
                <!-- Datos médico tratante -->
                <div class="dr_dataA5">
                  <div class="icon">
                    <img
                      src="/icons/vet_symbol.svg"
                      width="40px"
                      height="40px"
                    />
                  </div>
                  <div>
                    <strong>Dr. {{ atenciondia.Nombredoc }}</strong>
                    <p>RUN {{ atenciondia.Rutdoc }}</p>
                  </div>
                </div>
                <hr />
                <!-- Pie -->
                <div class="footer-verifyA5">
                  <div>
                    <div class="footer-titleA5">
                      <strong>Verifique este documento</strong>
                    </div>
                    <div class="verify_document">
                      <p>https://dash.clivenapp.cl/verify-doc/{{ docId }}</p>
                    </div>
                  </div>
                  <div>
                    <qrcode
                      :value="`https://dash.clivenapp.cl/verify-doc/${docId}`"
                      :options="{ width: 90 }"
                    ></qrcode>
                  </div>
                </div>
              </div>
            </section>
          </vue-html2pdf>
        </div>
      </div>
      <!-- Cliente no seleccionado -->
      <div v-else class="d-flex justify-center pa-3 ma-3">
        <div class="d-flex flex-column">
          <v-avatar tile size="300" class="d-flex justify-center">
            <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
          </v-avatar>
          <div class="d-flex justify-center">
            <h3 class="mt-3 ml-3">Seleccione una mascota</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- Vista de permisos necesarios -->
    <div v-else class="d-flex justify-center pa-3 ma-3">
      <div class="d-flex flex-column">
        <v-avatar tile size="300" class="d-flex justify-center">
          <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
        </v-avatar>
        <div class="d-flex justify-center">
          <h3 class="black_h3 mt-3 ml-3">
            No tiene los permisos necesarios para <br />
            acceder a los datos
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import GenerateCertificado from "../Components/GenerateCertificado.vue";
import ImprimirHistorialAtencionesdia from "../Components/ImprimirHistorial.vue";
import axios from "axios";

export default {
  components: {
    VueHtml2pdf,
    GenerateCertificado,
    ImprimirHistorialAtencionesdia,
  },
  data() {
    return {
      minvalue: 0,
      selectFilterProdus: "",
      activeViewHistoryAtenciodia: false,
      activeSearchCliente: false,
      headersServisHistory: [
        { text: "Descripción", value: "Descripcion" },
        { text: "Planificacion", value: "Fecha" },
        { text: "Estado", value: "Tareacumplida" },
        { text: "Acción", value: "action" },
      ],
      activeShowChecklist: false,
      headersClientes: [
        {
          text: "N° Ficha",
          align: "start",
          sortable: false,
          value: "Fichan",
        },
        { text: "Mascota", value: "Nombre" },
        { text: "Nombre", value: "Amo" },
        { text: "Rut", value: "Rut" },
        { text: "Teléfono", value: "Fono" },
        { text: "Seleccionar", value: "action" },
      ],
      headers: [
        { text: "Ficha", value: "Fichan" },
        { text: "Mascota", value: "Nombre" },
        { text: "Ingreso", value: "Hora" },
        { text: "Motivo", value: "Pago" },
        { text: "", value: "action" },
      ],
      expanded: null,
      expandedAtencion: null,
      search: "",
      selectPet: {
        id: "",
        pet_id: "",
        name: "",
        color: "",
        gender: "",
        birth_date: "",
        race: "",
        animal_type: "",
        signed_up: false,
        chip_number: 0,
        property_id: "",
        medical_record_id: "",
      },
      selectCustomer: {
        id: "",
        customer_id: "",
        name: "",
        customer_type: "",
        address: "",
        community: "",
        city: "",
        phone: "",
        mobile_phone: "",
        email: "",
        health_plan: "",
        comercial_activity: "",
        register_date: "",
        status: "",
        more_information: "",
        comment: "",
        pets_ids: [],
      },
      selectMedicalRecord: {
        id: "",
        date_created: "",
        pet_id: "",
        property_id: "",
      },
      selectServis: "",
      selectServisChecklist: "",
      filterProdus: [],
      activeNewServis: false,
      searchCustomer: "",
      activeNewReceta: false,
      searchProdus: "",
      headersProdus: [
        { text: "Producto o servicio", value: "Descripcion" },
        { text: "Codigo", value: "Codigo" },
        { text: "Selecionar", value: "action" },
      ],
      headersAtenciondiaByCliente: [
        { text: "Fecha", value: "Fecha" },
        { text: "Atentido por", value: "Nombredoc" },
        { text: "Motivo", value: "Pago" },
      ],
      headersRecetas: [
        { text: "Fecha", value: "Fecha" },
        { text: "Dr", value: "Dr" },
        { text: "Ver e imprimir", value: "action" },
      ],
      menuDatePlanning: false,
      hourPlanningServi: false,
      hourPlanningServi: "",
      servi: {
        Fecha: "",
        Hora: "",
        Ficha: 0,
        Mascota: "",
        Propietario: "",
        Fono: "",
        Quien: "",
        Quefecha: "",
        Codigo: "",
        Descripcion: "",
        Observaciones: "",
        Tareacumplida: "",
        Familia: "",
        Cantidad: 1,
        Var3: "",
        Var1: ".",
        Var2: ".",
        Subfamilia: "",
        Fechaentrega: "",
        Nboleta: "0",
        Tipodocumento: "No registra",
        Costo: 0,
        Precio: 0,
        Sucursal: "El Bosque",
      },
      dateControlChecklist: false,
      requireControl: false,
      pesoOptions: ["Delgado", "Normal", "Sobrepeso", "Obesidad"],
      ccOptions: [
        "1/9",
        "2/9",
        "3/9",
        "4/9",
        "5/9",
        "6/9",
        "7/9",
        "8/9",
        "9/9",
      ],
      pelajeOptions: ["Normal", "Soborrea", "Otra"],
      oidosOptions: [
        "Normal",
        "Cuerpo extraño",
        "Alergia presente",
        "Acaros del oido",
        "Polipos",
        "Otr",
      ],
      ojosOptions: ["Normal", "Ojo rojo", "Globo ocular", "Parpados", "Otro"],
      mucosasOptions: ["Normal", "Otra"],
      cavidadoralOptions: ["Normal", "Otro"],
      abdomenOptions: ["Miembros Toraxicos", "Mienbro Pelvicos", "Otro"],
      extremidadesOptions: ["Inferior", "Posterior", "Ambas"],
      diagOptions: [
        "Excelente estado",
        "Normal",
        "Requiere cuidados",
        "Debe seguir tratamiento",
        "Otros",
      ],

      solicitudesEspOptions: [
        "Examenes",
        "Radiografias",
        "Ecografias",
        "Control",
        "Otros",
      ],

      // Receta
      activeViewHistoryReceta: false,
      formatLetter: true,
      formatA4: false,
      formatA5: false,
      formartDoc: "letter",
      uploadingCreateReceta: false,
      // Var para activar la subida de archivos
      activeUploadFile: false,

      // Var para activar la generacion de certificado
      activeGenerateCert: false,
      // Fecha actual
      today: new Date().toISOString().substring(0, 10),
      // Control de archivos

      initiallyOpen: ["public"],
      files: {
        html: "mdi-language-html5",
        js: "mdi-nodejs",
        json: "mdi-code-json",
        md: "mdi-language-markdown",
        pdf: "mdi-file-pdf",
        png: "mdi-file-image",
        jpg: "mdi-file-image",
        jpge: "mdi-file-image",
        txt: "mdi-file-document-outline",
        xlsx: "mdi-file-excel",
        xls: "mdi-file-excel",
      },
      tree: [],
      activeShowSelectReceta: false,
      items: [
        {
          name: "public",
          children: [
            {
              name: "static",
              children: [
                {
                  name: "logo.png",
                  file: "png",
                },
              ],
            },
            {
              name: "favicon.ico",
              file: "png",
            },
            {
              name: "index.html",
              file: "html",
            },
          ],
        },
      ],
      uploadImage: "",
      selectFilterProdusChecklist: "",
      Images: [],
      Video: [],
      Examen: [],
      activeUploadFiles: false,
      activeUploadFileOver: false,
      filesemail: [],
      //diagnostico
      uploadingCreateDiagnostico: false,

      //certificado
      showGenerateCertificado: false,

      //carga y desactivación de creacipon de servicio
      activeUploadingCreateService: false,

      selectReceta: "",
      activeShowHistorialChecklist: false,
      activeShowSentEmail: false,
      //email
      email: {
        to: "",
        subject: "",
        body: "",
      },
      emailRule: (value) => {
        // Expresión regular para validar el formato del correo electrónico
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value) {
          return "El correo electrónico es requerido";
        } else if (!emailRegex.test(value)) {
          return "El correo electrónico debe tener un formato válido";
        } else {
          return true;
        }
      },
      activeShowPrinterHistorialAtencionesdia: false,
      activeUploadlingSentEmail: false,

      //atenciondia
      menuDateFilterAtencion: false,
      dateFilterAtencion: "",
      selectFichanCliente: "",
      selectNameCliente: "",
      activeShowSelectAtenciondiaByCliente: false,
      selectShowReceta: {
        Dr: "",
        Fecha: "",
        Ficha: 0,
        Receta: "",
      },
      dateChecklist: "",
      //uploadings
      activeUploadingChangueRecetas: false,
      activeDialogoHospitalizar: false,
      filesImagenes: [],
      filesExamanes: [],
      filesVideos: [],
      readersImagenes: [],
      docId: "",
      singleExpand: false,
      singleExpandAtencion: false,
      expanded: false,
      searchAtenciondiaByCliente: "",
    };
  },
  watch: {
    selectFichaClienteId: async function (val) {
      await this.getClienteByFichan(val);
      this.activeViewHistoryReceta = false;
      this.activeShowSentEmail = false;
      this.showGenerateCertificado = false;
      this.activeNewServis = false;
      this.activeUploadFiles = false;
      this.activeViewHistoryAtenciodia = false;
      this.activeNewReceta = false;
      this.activeUploadingChangueRecetas = true;
      this.searchDiagnosticoByFichan(this.cliente.Fichan);
      this.getServisHistory({ fichan: this.cliente.Fichan });
      await this.getAtenciondiasByCliente({ fichan: this.cliente.Fichan });
      let atenciondia = this.atenciondias.find(
        (item) => item.Fichan == this.cliente.Fichan
      );
      this.getAtenciondiasById({ id: atenciondia.Id });
      this.getChecklistsByFicha({ ficha: this.cliente.Fichan });
      this.getDirectoryFilesByToken({ fichan: this.cliente.Fichan });
      this.getRecetas({ fichan: this.cliente.Fichan });
    },
    selectFilterProdus: function (val) {
      this.filterProdus = this.produs.filter((item) => item.Ubicacion == val);
    },
    selectFilterProdusChecklist: function (val) {
      this.filterProdus = this.produs.filter((item) => item.Ubicacion == val);
      this.checklist.Soles = val;
    },
    successCreateDiagnostico: function (val) {
      if (val) {
        this.uploadingCreateDiagnostico = false;
        this.uploadingCreateDiagnostico = false;
      }
    },
    successCreateReceta: function (val) {
      if (val) {
        this.receta.Fecha = "";
        this.receta.Ficha = "";
        this.receta.Dr = "";
        this.receta.Receta = "";
        this.selectReceta = "";
        this.receta = "";
        this.selectReceta = "";
        this.receta = "";
        this.uploadingCreateReceta = false;
      }
    },
    successCreateService: function (val) {
      if (val) {
        this.activeUploadingCreateService = false;
        this.servi.Fecha = "";
        this.servi.Observaciones = "";
        this.hourPlanningServi = "";
        this.selectServis = "";
      }
    },
    successSentEmail: function (val) {
      if (val) {
        this.email.to = "";
        this.email.subject = "";
        this.email.body = "";
        this.activeUploadlingSentEmail = false;
      }
    },
    atenciondias: function () {
      this.activeLoadingAtenciondia = false;
    },
    formartDoc: function (format) {
      if (format == "letter") {
        this.formatLetter = true;
        this.formatA4 = false;
        this.formatA5 = false;
      }
      if (format == "a5") {
        this.formatA5 = true;
        this.formatLetter = false;
        this.formatA4 = false;
      }
      if (format == "a4") {
        this.formatA5 = false;
        this.formatLetter = false;
        this.formatA4 = true;
      }
    },
    recetas: function (val) {
      this.activeUploadingChangueRecetas = false;
    },
    successCreateChecklist: function (val) {
      if (val) {
        if (this.requireControl) {
          this.requireControl = false;
          this.dateChecklist = "";
          this.selectServisChecklist = "";
          this.checklist.Solobs = "";
        }
      }
    },
  },
  methods: {
    ...mapActions("customers-module", [
      "getClienteByFichan",
      "selectFichaCliente",
      "getClientes",
    ]),
    ...mapActions("medical-module", [
      "getDiagnosticoByDateFichan",
      "getChecklistByDateFichan",
      "putDiagnosticoById",
      "getServisHistory",
      "getAtenciondiasByCliente",
      "getAtenciondias",
      "getAtenciondiasById",
      "getChecklistsByFicha",
      "putChecklistById",
      "postServis",
      "postUploadImage",
      "getDirAllFiles",
      "getDirectoryFilesByToken",
      "getRecetas",
      "postReceta",
      "selectReceta",
      "postSentEmail",
      "setSelectFichaHospitalizado",
      "postFilesImagenes",
      "postFilesExamenes",
      "postFilesVideos",
      "actionDeleteFileByPath",
      "postFilesEmailStorage",
      "postUpdateServisEntrega",
      "getProdusByNameOrCode"
    ]),

    btnGoReceta() {},
    btnSearchCliente() {
      this.getClientes(this.search);
    },
    btnActiveShowReceta() {
      this.activeNewReceta = true;
      setTimeout(() => {
        var element = this.$refs["cardreceta"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 110);
      }, 300);
    },
    //boton para mostrar tarjeta de servicios
    btnActiveNewServis() {
      this.activeNewServis = true;
      setTimeout(() => {
        var element = this.$refs["cardservis"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 110);
      }, 300);
    },
    btnActiveShowEmail() {
      this.activeShowSentEmail = true;
      setTimeout(() => {
        var element = this.$refs["cardemail"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 102);
      }, 300);
    },
    btnActiveShowCertificado() {
      this.showGenerateCertificado = true;
      this.getDirectoryFilesByToken({ fichan: this.cliente.Fichan });
      setTimeout(() => {
        var element = this.$refs["cardcert"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 101);
      }, 300);
    },
    btnActiveShowChecklist() {
      this.activeShowChecklist = true;
      setTimeout(() => {
        var element = this.$refs["cardchecklist"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 110);
      }, 300);
    },
    btnActiveShowArchivos() {
      this.activeUploadFiles = true;
      setTimeout(() => {
        var element = this.$refs["cardfile"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 105);
      }, 300);
    },
    //gestion e impresion receta
    btnCreateReceta(edocument) {
      this.uploadingCreateReceta = true;
      this.receta.Dr = this.atenciondia.Nombredoc;
      this.receta.Fecha = new Date().toISOString().substring(0, 10);
      this.receta.Ficha = this.cliente.Fichan;
      this.receta.Receta = this.selectReceta;
      this.postReceta({ receta: this.receta, edocument: edocument });
    },
    btnSelectReceta(id) {
      let showreceta = this.recetas.find((item) => item.Id == parseInt(id));
      this.activeShowSelectReceta = true;
      this.selectShowReceta = showreceta;
      this.selectReceta = showreceta.Receta;
    },
    btnHideSelectReceta() {
      this.activeShowSelectReceta = false;
      this.selectShowReceta = {
        Dr: "",
        Fecha: "",
        Ficha: 0,
        Receta: "",
      };
      this.selectReceta = "";
    },
    btnSelectFormartReceta(format) {
      if (format == "letter") {
        this.formatLetter = true;
        this.formatA4 = false;
        this.formatA5 = false;
      }
      if (format == "a5") {
        this.formatA5 = true;
        this.formatLetter = false;
        this.formatA4 = false;
      }
      if (format == "a4") {
        this.formatA5 = false;
        this.formatLetter = false;
        this.formatA4 = true;
      }
      this.formartDoc = format;
    },
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    },
    btnGenerateReceta(type) {
      this.docId = this.generateUUID();
      var edocument = {
        Id: this.docId,
        TipoDoc: "receta",
        Tipo_certificado: "",
        Cliente_nombre: this.cliente.Amo,
        Cliente_rut: this.cliente.Rut,
        Cliente_direccion: this.cliente.Direccion + ", " + this.cliente.Comuna,
        Cliente_comuna: this.cliente.Comuna,
        Cliente_ciudad: this.cliente.Ciudad,
        Cliente_mascota: this.cliente.Nombre,
        Cliente_ficha: this.cliente.Fichan,
        Mascota_nacimiento: "2001-01-01 00:00:00",
        Mascota_color: this.cliente.Color,
        Microchip: this.cliente.Microchip,
        Mascota_sexo: this.cliente.Sexo,
        Mascota_raza: this.cliente.Raza,
        Mascota_especie: this.cliente.Esp,
        Doctor_nombre: this.atenciondia.Nombredoc,
        Doctor_rut: this.atenciondia.Rutdoc,
        Clinica: this.clinicaUser.Name,
        Clinica_sucursal: this.user.Sucursal,
        Prescripcion: this.selectReceta,
        Certificado_titulo: "",
        Certificado_descripcion: "",
        Certificado_hotel_ingreso: "2001-01-01 00:00:00",
        Certificado_hotel_salida: "2001-01-01 00:00:00",
        Certificado_hotel_estadia: "",
        Certificado_hotel_alimento: "",
        Certificado_hotel_transporte: "",
        Certificado_radio_examen: "",
        Certificado_radio_antecedente: "",
        Certificado_radio_interpretacion: "",
        Certificado_radio_radiodiagnostico: "",
        Certificado_vacuna_nombre: "",
        Certificado_vacuna_fecha: "2001-01-01 00:00:00",
        Fecha_creacion: "",
      };
      this.$refs.html2Pdf.generatePdf();
      this.btnCreateReceta(edocument);
    },
    btnGenerateSelectReceta(type) {
      this.$refs.html2Pdf.generatePdf();
    },
    async btnSelectFichanCustomer(cliente) {
      await this.getAtenciondiasByCliente({ fichan: cliente.Fichan });
      this.selectFichanCliente = cliente.Fichan;
      this.selectNameCliente = cliente.Nombre;
      this.activeShowSelectAtenciondiaByCliente = true;
    },
    async btnSelectAtenciondiaByCliente(atenciondia) {
      this.activeShowSelectAtenciondiaByCliente = false;
      this.activeSearchCliente = false;
      this.activeViewHistoryReceta = false;
      this.activeShowSentEmail = false;
      this.showGenerateCertificado = false;
      this.activeNewServis = false;
      this.activeUploadFiles = false;
      this.activeViewHistoryAtenciodia = false;
      this.activeUploadingChangueRecetas = true;
      await this.getClienteByFichan(atenciondia.Fichan);
      this.searchDiagnosticoByAtenciondia(atenciondia);
      this.getServisHistory({ fichan: atenciondia.Fichan });
      await this.getAtenciondiasByCliente({ fichan: atenciondia.Fichan });
      this.getAtenciondiasById({ id: atenciondia.Id });
      this.getChecklistsByFicha({ ficha: this.cliente.Fichan });
      this.getDirAllFiles({ Fichan: this.cliente.Fichan });

      this.getRecetas({ fichan: this.cliente.Fichan });
    },
    getColorHealth(health) {
      if (health == "no urgente") {
        return "blue";
      }
      if (health == "leve") {
        return "green";
      }
      if (health == "moderado") {
        return "yelow";
      }
      if (health == "grave") {
        return "oragen";
      }
      if (health == "muy grave") {
        return "red";
      }
    },
    setDateTimeString(date) {
      var dataSet = new Date(date);
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (m < 10) {
        m = "0" + m;
      }
      if (h == 0) {
        h = "00";
      }
      return h + ":" + m + " hrs";
    },
    setDateLargeString(date) {
      var dataSet = new Date(date);
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (m < 10) {
        m = "0" + m;
      }
      if (h == 0) {
        h = "00";
      }

      return (
        day +
        " de " +
        montTex +
        " de " +
        year +
        " a las " +
        h +
        ":" +
        m +
        " hrs"
      );
    },
    setDateShortString(date) {
      var dataSet = new Date(date.substring(0, 10) + "T00:00:00");
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " de " + year;
    },
    setDateStringTimeZoneShort(date) {
      var dataSet = new Date(date.substring(0, 10) + "T00:00:00");

      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year;
    },
    setDateNow(date) {
      var currentDateObj = date;
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj;
    },
    setDateSumNow(date) {
      var currentDateObj = date;
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
      return newDateObj;
    },
    setMedicalRecord() {
      this.selectMedicalRecord = this.getMedicalRecordById(
        this.$route.params.id
      );
      this.selectPet = this.getPetById(this.selectMedicalRecord.pet_id);
      this.selectCustomer = this.getCustomerById(
        this.selectMedicalRecord.property_id
      );
    },
    getAge(dateString) {
      var now = new Date();
      var today = new Date(now.getYear(), now.getMonth(), now.getDate());

      var yearNow = now.getYear();
      var monthNow = now.getMonth();
      var dateNow = now.getDate();

      var dob = new Date(
        dateString.substring(0, 4),
        dateString.substring(5, 7) - 1,
        dateString.substring(8, 10)
      );

      var yearDob = dob.getYear();
      var monthDob = dob.getMonth();
      var dateDob = dob.getDate();
      var age = {};
      var ageString = "";
      var yearString = "";
      var monthString = "";
      var dayString = "";

      let yearAge = yearNow - yearDob;

      if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
      else {
        yearAge--;
        var monthAge = 12 + monthNow - monthDob;
      }

      if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
      else {
        monthAge--;
        var dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge,
      };

      if (age.years > 1) yearString = " años";
      else yearString = " año";
      if (age.months > 1) monthString = " meses";
      else monthString = " mes";
      if (age.days > 1) dayString = " días";
      else dayString = " día";

      if (age.years > 0 && age.months > 0 && age.days > 0)
        ageString =
          age.years +
          yearString +
          ", " +
          age.months +
          monthString +
          ", y " +
          age.days +
          dayString;
      else if (age.years == 0 && age.months == 0 && age.days > 0)
        ageString = "solo " + age.days + dayString;
      else if (age.years > 0 && age.months == 0 && age.days == 0)
        ageString = age.years + yearString + " Feliz cumpleaños!!";
      else if (age.years > 0 && age.months > 0 && age.days == 0)
        ageString = age.years + yearString + " y " + age.months + monthString;
      else if (age.years == 0 && age.months > 0 && age.days > 0)
        ageString =
          age.months + monthString + " y " + age.days + dayString + ".";
      else if (age.years > 0 && age.months == 0 && age.days > 0)
        ageString = age.years + yearString + " y " + age.days + dayString + ".";
      else if (age.years == 0 && age.months > 0 && age.days == 0)
        ageString = age.months + monthString + " .";
      else ageString = "Oops! No es posible calcular!";

      return ageString;
    },
    setMedicalRecordFromTable(id) {
      this.selectMedicalRecord = this.getMedicalRecordById(id);
      this.selectPet = this.getPetById(this.selectMedicalRecord.pet_id);
      this.selectCustomer = this.getCustomerById(
        this.selectMedicalRecord.property_id
      );
    },
    setDateNacimiento(nacimiento) {
      return nacimiento.substring(0, 10);
    },
    async searchSelectClienteById() {
      await this.getClienteByFichan(this.selectFichaClienteId);
      setTimeout(() => {
        var element = this.$refs["centralViewAtencion"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 101);
      }, 300);
      this.searchDiagnosticoByFichan(this.cliente.Fichan);
      this.getServisHistory({ fichan: this.cliente.Fichan });
      await this.getAtenciondiasByCliente({ fichan: this.cliente.Fichan });
      let atenciondia = this.atenciondias.find(
        (item) => item.Fichan == this.cliente.Fichan
      );
      this.getAtenciondiasById({ id: atenciondia.Id });
      this.getChecklistsByFicha({ ficha: this.cliente.Fichan });
    },
    btnSelectFichaCliente(id) {
      this.selectFichaCliente(id);
      setTimeout(() => {
        var element = this.$refs["atencionref"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 100);
      }, 300);
    },
    searchClientes() {
      this.getClientes(this.searchCustomer);
    },

    //actualizar diagnostico
    updateDiagnostico() {
      this.uploadingCreateDiagnostico = true;
      this.diagnostico.Fecha = this.diagnostico.Fecha.substring(0, 10);
      let data = {
        id: this.diagnostico.Id,
        diagnostico: this.diagnostico,
        id_atenciondia: this.atenciondia.Id,
        atenciondia: this.atenciondia,
      };
      this.putDiagnosticoById(data);
    },
    searchDiagnosticoByFichan(fichan) {
      let atenciondia = this.atenciondias.find((item) => item.Fichan == fichan);
      this.getDiagnosticoByDateFichan({
        fichan: fichan,
        fecha: atenciondia.Fecha.substring(0, 10),
      });
      this.getChecklistByDateFichan({
        fichan: fichan,
        fecha: atenciondia.Fecha.substring(0, 10),
      });
    },
    searchDiagnosticoByAtenciondia(atenciondia) {
      this.getDiagnosticoByDateFichan({
        fichan: atenciondia.Fichan,
        fecha: atenciondia.Fecha.substring(0, 10),
      });
      this.getChecklistByDateFichan({
        fichan: atenciondia.Fichan,
        fecha: atenciondia.Fecha.substring(0, 10),
      });
    },

    //servis
    btnSelectServis(produ) {
      this.selectServis = produ.Descripcion;
      this.servi.Codigo = produ.Codigo;
      this.servi.Descripcion = produ.Descripcion;
      this.servi.Familia = produ.Ubicacion;
      this.servi.Subfamilia = produ.Subfamilia;
      this.servi.Costo = produ.Costo;
      this.servi.Precio = produ.Vtadeta;
    },
    btnSelectServisChecklist(produ) {
      this.selectServisChecklist = produ.Descripcion;
      this.servi.Codigo = produ.Codigo;
      this.servi.Descripcion = produ.Descripcion;
      this.servi.Familia = produ.Ubicacion;
      this.servi.Subfamilia = produ.Subfamilia;
      this.servi.Costo = produ.Costo;
      this.servi.Precio = produ.Vtadeta;
    },
    btnCreateNewServis(type) {
      this.activeUploadingCreateService = true;
      if (type == "planning") {
        this.servi.Tareacumplida = "Pendiente";
        this.servi.Fechaentrega = "2001-01-01";
      } else {
        this.servi.Tareacumplida = "Entregado";
        this.servi.Fechaentrega = new Date().toISOString().substring(0, 10);
      }
      let atenciondia = this.atenciondias.find(
        (item) => item.Fichan == this.cliente.Fichan
      );
      this.servi.Tipodocumento = "0";
      this.servi.Quefecha = this.servi.Fecha;
      this.servi.Nboleta = "0";
      this.servi.Var1 = "0";
      this.servi.Var2 = "0";
      this.servi.Cantidad = 1;
      this.servi.Quien = atenciondia.Nombredoc;
      this.servi.Var3 = new Date().toISOString().substring(0, 10);
      this.servi.Hora = this.servi.Fecha + " " + this.hourPlanningServi + ":00";
      this.servi.Mascota = this.cliente.Nombre;
      this.servi.Propietario = this.cliente.Amo;
      this.servi.Ficha = this.cliente.Fichan;
      this.servi.Fono = this.cliente.Altura;
      this.servi.Var3 = new Date().toISOString().substring(0, 10);
      this.postServis({ servi: this.servi });
    },

    //checklist
    btnUpdateChecklist() {
      if (this.requireControl) {
        if (this.dateChecklist == "") {
          alert("debe seleccionar una fecha para la programacion del servicio");
          return;
        }
        this.checklist.Require = "si";
        this.servi.Tareacumplida = "Pendiente";
        this.servi.Fechaentrega = "2001-01-01";
        this.servi.Fecha = this.dateChecklist;

        let atenciondia = this.atenciondias.find(
          (item) => item.Fichan == this.cliente.Fichan
        );
        this.servi.Tipodocumento = "0";
        this.servi.Quefecha = this.servi.Fecha;
        this.servi.Nboleta = "0";
        this.servi.Var1 = "0";
        this.servi.Var2 = "0";
        this.servi.Cantidad = 1;
        this.servi.Quien = atenciondia.Nombredoc;
        this.servi.Var3 = new Date().toISOString().substring(0, 10);
        this.servi.Hora = this.dateChecklist + " " + "00:00";
        this.servi.Mascota = this.cliente.Nombre;
        this.servi.Propietario = this.cliente.Amo;
        this.servi.Ficha = this.cliente.Fichan;
        this.servi.Fono = this.cliente.Altura;
        this.servi.Var3 = new Date().toISOString().substring(0, 10);
        this.postServis({ servi: this.servi });
      } else {
        this.checklist.Require = "no";
      }
      this.putChecklistById({
        id: this.checklist.Id,
        checklist: this.checklist,
      });
    },

    //subir imagen
    async btnUploadImage() {
      try {
        let bodyFormData = new FormData();
        bodyFormData.append("file", this.uploadImage);
        let query = `https://service.clivenapp.cl/upload-image`;
        let headers = {
          "Content-type": "multipart/form-data",
        };
        // console.log(bodyFormData);
        await axios.post(query, bodyFormData, { headers: headers });
      } catch (error) {
        // console.log(error);
        throw new Error("no se pudo cargar la imagen");
      }
    },
    uploadFile() {
      this.Images = this.$refs.file.files[0];
    },
    submitFile() {
      this.activeUploadFileOver = true;
      let atenciondia = this.atenciondias.find(
        (item) => item.Fichan == this.cliente.Fichan
      );
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      const formData = new FormData();
      formData.append("file", this.Images);
      const headers = { "Content-Type": "multipart/form-data" };

      axios
        .post(
          `https://service.clivenapp.cl/upload-image?token=${
            clinicaUser.Token
          }&fichan=${
            this.cliente.Fichan
          }&fecha=${atenciondia.Fechahora.substring(0, 10)}`,
          formData,
          { headers }
        )
        .then((res) => {
          res.data.files; // binary representation of the file
          res.status; // HTTP status
          this.activeUploadFileOver = false;
          this.getDirAllFiles({ Fichan: this.cliente.Fichan });
        });
    },
    uploadFileVideo() {
      this.Video = this.$refs.filevideo.files[0];
    },
    submitFileVideo() {
      this.activeUploadFileOver = true;
      let atenciondia = this.atenciondias.find(
        (item) => item.Fichan == this.cliente.Fichan
      );
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      const formData = new FormData();
      formData.append("file", this.Video);
      const headers = { "Content-Type": "multipart/form-data" };
      axios
        .post(
          `https://service.clivenapp.cl/upload-videos?token=${
            clinicaUser.Token
          }&fichan=${
            this.cliente.Fichan
          }&fecha=${atenciondia.Fechahora.substring(0, 10)}`,
          formData,
          { headers }
        )
        .then((res) => {
          res.data.files; // binary representation of the file
          res.status; // HTTP status
          this.activeUploadFileOver = false;
          this.getDirAllFiles({ Fichan: this.cliente.Fichan });
        });
    },
    addFilesImagenes() {
      this.filesImagenes.forEach((file, f) => {
        this.readersImagenes[f] = new FileReader();
        this.readersImagenes[f].onloadend = (e) => {
          let fileData = this.readersImagenes[f].result;
          let imgRef = file[f];
          imgRef.src = fileData;
          // console.log(fileData);
          // send to server here...
        };

        this.readersImagenes[f].readAsDataURL(this.filesImagenes[f]);
      });
    },
    btnSubmitImagenes() {
      this.postFilesImagenes({
        imagenes: this.filesImagenes,
        date: this.atenciondia.Fecha,
        fichan: this.cliente.Fichan,
      });
    },
    btnSubmitVideos() {
      this.postFilesVideos({
        videos: this.filesVideos,
        date: this.atenciondia.Fecha,
        fichan: this.cliente.Fichan,
      });
    },
    btnSubmitExamenes() {
      this.postFilesExamenes({
        examenes: this.filesExamanes,
        date: this.atenciondia.Fecha,
        fichan: this.cliente.Fichan,
      });
    },

    getUrlImagenes(index) {
      let uri = URL.createObjectURL(this.filesImagenes[index]);
      return uri;
    },
    //subir examen
    uploadFileExamen() {
      this.Examen = this.$refs.fileexamen.files[0];
    },

    //enviar examen
    submitFileExamen() {
      this.activeUploadFileOver = true;
      let atenciondia = this.atenciondias.find(
        (item) => item.Fichan == this.cliente.Fichan
      );
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      const formData = new FormData();
      formData.append("file", this.Examen);
      const headers = { "Content-Type": "multipart/form-data" };
      axios
        .post(
          `https://service.clivenapp.cl/upload-examen?token=${
            clinicaUser.Token
          }&fichan=${
            this.cliente.Fichan
          }&fecha=${atenciondia.Fechahora.substring(0, 10)}`,
          formData,
          { headers }
        )
        .then((res) => {
          res.data.files; // binary representation of the file
          res.status; // HTTP status
          this.activeUploadFileOver = false;
          this.getDirAllFiles({ Fichan: this.cliente.Fichan });
        });
    },

    //Email
    //subir email
    uploadFilesEmail() {
      for (let i of this.$refs.filesUpMail.files) {
        this.filesemail.push(i);
      }
    },

    //enviar archivos email
    async submitFilesEmail(dir) {
      this.activeUploadFileOver = true;
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      const formData = new FormData();
      for (let i of this.filesemail) {
        formData.append("file", i);
      }
      const headers = { "Content-Type": "multipart/form-data" };
      await axios
        .post(
          `https://service.clivenapp.cl/email/upload-files?token=${clinicaUser.Token}&id_attchment=${dir}`,
          formData,
          { headers }
        )
        .then((res) => {
          // console.log(res);
          res.data.files; // binary representation of the file
          res.status; // HTTP status
          this.activeUploadFileOver = false;
        });
    },

    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    },

    //boton enviar mail
    async btnSentEmail() {
      this.activeUploadlingSentEmail = true;
      let email = {
        to: this.email.to,
        subject: this.email.subject,
        body: this.email.body,
      };
      let resultUpload = await this.postFilesEmailStorage({
        imagenes: this.filesemail,
      });
      let links = "";

      await resultUpload.forEach((element) => {
        let setlink = `<a href='${element.url}' target='_blank' rel='noopener noreferrer'>${element.name}</a><br>`;
        links += setlink;
      });

      let textoHTML = email.body.replace(/\n/g, "<br>");
      let newbody =
        "<p>" +
        "<h2>Asunto:" +
        email.subject +
        "</h2><br>" +
        textoHTML +
        "<br>" +
        links +
        "<br>" +
        "* Los archivos de imagenes, pdf y videos los puede visualizar directamente pinchando el link en los nombres, para otros archivos dependiendo del navegador puede requerir que copia manualmente el link en el navegador para descargar, para esto vaya al nombre del archivo presione click derecho y pinche donde dice 'Copiar la dirección del vinculo y pegue en el navegador' <br> Este email es enviado a través de la aplicacion, no debe responderlo" +
        "</p>";
      email.body = newbody;
      email.sede = this.user.Sucursal;
      email.clinica = this.clinicaUser.Name;

      //console.log(email);
      this.postSentEmail(email);
    },
    //descargar archivo
    btnDownload(file) {
      let url = `https://storage.googleapis.com/clivenapp-3909d.appspot.com${file.path}`;
      let strMimeType;
      let strFileName;

      //Perform a GET Request to server
      fetch(url)
        .then(function (response) {
          let contentType = response.headers.get("Content-Type"); //Get File name from content type
          var name = file.path;
          let file_ext = name.substr(name.lastIndexOf("/") + 1, name.length);
          strMimeType = response.headers.get("content-type").split(";")[0];
          strFileName = file_ext;
          return response.blob();
        })
        .then(function (myBlob) {
          let downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([myBlob], { type: strMimeType })
          );
          downloadLink.download = strFileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        })
        .catch((e) => {});
    },
    btnDelete(file) {
      //Perform a GET Request to server
      this.actionDeleteFileByPath({
        path: file.path,
        fichan: this.cliente.Fichan,
      });
    },

    //atenciondia
    btnFilterDateAtenciondia() {
      this.menuDateFilterAtencion = false;
      this.activeLoadingAtenciondia = true;
      this.getAtenciondias({
        fecha: this.dateFilterAtencion,
        sucursal: this.user.Sucursal,
      });
    },
    setColorPago(atencion) {
      if (atencion == "&H80FF80") {
        return "green";
      } else {
        return "primary";
      }
    },

    //go to ref
    btnGoToRefFichaMedica() {
      var element = this.$refs["atenciondiaview"];
      var top = element.offsetTop;
      window.scrollTo(0, top - 110);
    },

    setUpperString(text) {
      return text.toUpperCase();
    },

    btnCreateHospitalizacionByFicha() {
      this.setSelectFichaHospitalizado(this.cliente.Fichan);
      setTimeout(() => {
        this.$router.push("/medical/hospital");
      }, 400);
    },
    btnApplyServisById(servis) {
      servis.Fecha = servis.Fecha.substring(0, 10);
      servis.Quefecha = servis.Quefecha.substring(0, 10);
      servis.Var3 = servis.Var3.substring(0, 10);
      servis.Fechaentrega = new Date().toISOString().substring(0, 10);
      this.postUpdateServisEntrega({ servi: servis });
    },
    btnSearchProductByServiece(){
      if(!this.searchProdus){
        alert("Debe ingresar un parametro de busqueda")
        return
      }
      this.getProdusByNameOrCode({search: this.searchProdus})
    }
  },

  computed: {
    ...mapState("medical-module", [
      "medicalCareVisits",
      "doctors",
      "doctor",
      "motivovisitas",
      "atenciondias",
      "diagnostico",
      "fames",
      "produs",
      "servisHistory",
      "atenciondiasByCliente",
      "atenciondia",
      "checklists",
      "checklist",
      "directories",
      "successCreateDiagnostico",
      "successCreateChecklist",
      "successCreateService",
      "recetas",
      "receta",
      "successCreateReceta",
      "successSentEmail",
      "loadingUpload",
      "successDeleteFile",
      "activeCreateServisEntregado",
      "activeLoadingCargarProdu"
    ]),
    ...mapState("pets-module", ["pets"]),
    ...mapState("auth-module", [
      "licen",
      "logoUpload",
      "user",
      "clinica",
      "clinicaUser",
    ]),
    ...mapGetters("pets-module", ["getPetNameById", "getPetById"]),
    ...mapGetters("medical-module", ["getMedicalRecordById"]),
    ...mapState("customers-module", [
      "customers",
      "selectFichaClienteId",
      "cliente",
      "clientes",
      "loadingClientes",
    ]),
    ...mapGetters("customers-module", [
      "getCustomerNameById",
      "getCustomerById",
    ]),
    setFilterProdus() {
      let filter = [];
      this.produs.forEach((element) => {
        let search = filter.findIndex(
          (item) => item.Ubicacion == element.Ubicacion
        );
        if (search == -1) {
          filter.push(element.Ubicacion);
        }
      });
      return filter;
    },

    setData() {
      return this.selectReceta.replace(/(?:\r\n|\r|\n)/g, "<br/>");
    },
    validatedSaveDiagnosticos() {
      let result = true;
      const fechaInicioObj = new Date(this.diagnostico.Fecha);
      const fechaFinObj = new Date();

      const diferenciaMs = fechaFinObj - fechaInicioObj;
      const dias = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24));
      if (dias >= 2) {
        result = false;
      }
      return result;
    },
  },
  created() {
    this.searchSelectClienteById();
  },
};
</script>
<style>
/* General */
* {
  letter-spacing: 1px;
}

/* Título de la página */
.sticky-card {
  position: sticky;
  top: 100px;
  z-index: 1;
  background: #fff;
}
.titleContainer {
  z-index: 2;
}

/* Diseño web responsivo a contenedor de Botones de acción del título */
@media only screen and (min-width: 700px) {
  .actions_title {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 700px) {
  .actions_title {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-left: 1.5em;
  }
}
@media only screen and (max-width: 441px) {
  .btn_container {
    padding-top: 8px;
  }
}

/* Eliminar select Sort By desplegado en vista móvil de tabla  */
.v-data-table-header-mobile__wrapper {
  display: none;
  border-style: none;
}
.v-data-table-header-mobile {
  display: none;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Seleccione archivo";
  display: flex;
  border: 1.5px solid #003186;
  border-radius: 3px;
  border-color: "red";
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  color: #003186;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border: 2px solid #003186;
}
/* Estilos a H3 (utilizado para mostrar el nombre y año de creación de la mascota) */
h3 {
  color: #0d47a1;
}
/* Cambio de color al h3 */
.black_h3 {
  color: black;
}
/* Historial de atenciones (separación en vista móvil) */
@media only screen and (max-width: 600px) {
  .tipo {
    margin-top: 5px;
  }
  .doc {
    margin-top: 5px;
  }
}
/* Nombre doctor en historial de atenciones */
.nombreDoc {
  font-weight: 600;
}
/* Responsive web design (Historial de atenciones) */
@media only screen and (max-width: 400px) {
  .secondColumnRecordHistory {
    padding-right: 5px;
  }
  .secondColumnRecordHistory .historyDivider {
    margin-right: 5px;
  }
  .v-expansion-panel-header__icon .v-icon {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 400px) {
  .secondColumnRecordHistory {
    padding-right: 30px;
  }
  .secondColumnRecordHistory .historyDivider {
    margin-right: 10px;
  }
}

/* Contenido receta a4 (carta) */
strong {
  font-weight: 800;
  letter-spacing: 2px;
}
.contentrecetaA4 {
  height: 1150px;
}
.contentrecetaLetter {
  height: 1000px;
}
/* Encabezado */
.headerA4 {
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 15px;
  height: 10%;
  display: flex;
  justify-content: center;
}
.containt_headerA4 {
  display: flex;
  justify-content: space-between;
}

.headerLogo {
  display: flex;
  align-items: center;
  width: 50%;
  flex-direction: column;
  padding-top: 15px;
  justify-content: center;
}
.logo {
  object-fit: contain;
  margin-top: 15px;
  margin-left: 10px;
}
.containt_logoA4 {
  width: 23%;
  justify-content: end;
}

.logoA4 {
  object-fit: contain;
  margin-top: 15px;
  margin-left: 30px;
}
.main_titleA4 {
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  font-weight: bolder;
  letter-spacing: 2px;
  font-size: 16px;
}
/* Fin encabezado */
/* Subtítulo */
.subtitleA4 {
  display: flex;
  align-items: center;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 15px;
}
.firstColumnSubtitle {
  width: 60%;
}
.id_containerA4 {
  display: flex;
}
.idA4 {
  margin-left: 3px;
}
.fechaA4 {
  margin-left: 3px;
}
.date_containerA4 {
  display: flex;
  margin-top: 3px;
}
.amo_containerA4 {
  display: flex;
  margin-top: 10px;
}
.amoA4 {
  margin: 0px;
  margin-left: 3px;
}
.run_containerA4 {
  display: flex;
  margin-top: 3px;
}
.runA4 {
  margin: 0px;
  margin-left: 3px;
}
/* Logo */
.secondColumnSubtitle {
  width: 40%;
}
.secondColumnSubtitle .logoContainer {
  display: flex;
  align-items: center;
}
.logoContainer .logo {
  margin-right: 7px;
}
/* Fin subtítulo */

/* Datos paciente */
.customersectionA4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  height: 12%;
}
.addressA4 {
  padding-right: 10px;
}
.first_row_mascota_dato {
  display: flex;
}
.second_row_mascota_dato {
  display: flex;
  padding-top: 1.2em;
}
.columnA4_second_row {
  width: 37%;
}
.columnA4bigger {
  width: 46%;
}
/* Fin datos paciente */
/* Prescripción */
.descriptionRecetaA4 {
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 20px;
  height: 48%;
}
/* Fin prescripción */
/* Datos médico tratante */
.dr_data {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 10%;
  width: 100%;
}
.icon {
  margin-right: 10px;
}
/* Fin datos médico tratante */
/* Línea divisoria (horizontal) */
hr {
  margin: auto;
  border: 0.2px solid #e9ebe9;
  width: 95%;
}
/* Pie (footer)  */
.footer-verifyA4 {
  display: flex;
  width: 867px;
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 20px;
  height: 10%;
  align-content: center;
}
.first_column_footer {
  width: 60%;
}
.verify_document {
  margin-top: 10px;
  margin-right: 100px;
  background: #f7f7f7;
  padding: 5px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.msg_qr {
  padding-top: 20px;
}
.second_column_footer {
  width: 40%;
}
.qr_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.qr {
  margin-top: 10px;
  width: 80px;
}
/* Fin pie */
/* Fin contenido a4 */

/* Formato A5 (Recetario) */
/* Contenido receta a5 */
/* Encabezado */
.contentrecetaA5 {
  width: 560px;
  height: 770px;
}
.headerA5 {
  padding-left: 30px;
  padding-top: 30px;
  width: 100%;
  align-items: center;
  height: 7%;
  display: flex;
  flex-direction: column;
}
.main_titleA5 {
  font-weight: bolder;
  letter-spacing: 2px;
  font-size: 16px;
}
/* Fin encabezado */
/* Subtítulo */
.subtitleA5 {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 30px;
  padding-bottom: 10px;
}
.firstColumnSubtitleA5 {
  width: 60%;
}
.id_containerA5 {
  display: flex;
}
.idA5 {
  margin-left: 5px;
}
.fechaA5 {
  margin-left: 5px;
}
.date_containerA5 {
  display: flex;
  margin-top: 3px;
}
.amo_containerA5 {
  display: flex;
  margin-top: 10px;
}
.amoA5 {
  margin: 0px;
  margin-left: 3px;
}
.run_containerA5 {
  display: flex;
  margin-top: 3px;
}
.runA5 {
  margin: 0px;
  margin-left: 3px;
}
.secondColumnSubtitleA5 {
  width: 40%;
}
.secondColumnSubtitleA5 .logoContainer {
  display: flex;
  align-items: center;
}
/* Fin subtítulo */

/* Datos paciente */
.customersectionA5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  height: 14%;
}
.addressA5 {
  padding-right: 15px;
}
.first_row_mascota_datoA5 {
  display: flex;
  width: 100%;
}
.second_row_mascota_datoA5 {
  display: flex;
  padding-top: 20px;
  margin: 0;
  width: 100%;
}
.columnA5 {
  width: 35%;
}
.columnA5lesswWidth {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.textologo {
  margin-top: 15px;
}
/* Fin datos paciente */

/* Prescripción */
.descriptionRecetaA5 {
  padding-left: 30px;
  padding-top: 10px;
  height: 43%;
}
/* Fin prescripción */

/* Datos médico tratante */
.dr_dataA5 {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 8%;
}
.icon {
  margin-right: 10px;
}
/* Fin datos médico tratante */
/* Línea divisoria (horizontal) */
hr {
  margin: auto;
  border: 0.2px solid #e9ebe9;
  width: 95%;
}
/* Pie (footer)  */
.footer-verifyA5 {
  display: flex;
  align-content: center;
  padding-right: 50px;
  padding-left: 30px;
  padding-top: 7px;
  height: 10%;
}
.footer-verifyA5 .footer-titleA5 {
  padding-top: 10px;
}
.verify_documentA5 {
  margin-top: 5px;
  margin-right: 60px;
  background: #f7f7f7;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.contenedor-firma {
  position: relative;
  display: inline-block;
  width: 300px;
}
.texto-encina {
  position: absolute;
  width: 100%;
  top: 55px;
  left: 10px;
}
/* Fin pie */
/* Fin contenido A5 */
</style>
