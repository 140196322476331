<template>
  <div class="ma-2 my-5">
    <v-card width="100%" class="pa-3 mb-5 rounded-xl">
      <div class="pa-3">
        <!-- titulo y accion de cerrar componenete -->
        <v-row class="card-title d-flex pa-3 mt-4 ml-4">
          <h2>Generación de certificados</h2>
          <v-spacer></v-spacer>
          <v-btn color="primary" icon @click="disableShowGenerateCertificado">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <!-- Selección de tipo de certificado -->
        <v-row>
          <v-col class="ml-2" cols="5">
            <v-select
              dense
              outlined
              v-model="selectCert"
              @change="selectCertificado"
              :items="certificados"
              item-text="name"
              item-value="id"
              placeholder="Tipo de certificado"
            ></v-select>
          </v-col>
        </v-row>
        <div v-if="certificadoHotel">
          <h2 class="ml-2 mb-5">Certificado de hotel</h2>
          <!-- formulario -->
          <v-row class="ma-0 pa-0">
            <!-- seleccionde estadia -->
            <v-col cols="2.5">
              <h2>Estadía</h2>
              <v-radio-group mandatory v-model="selectEstadia">
                <v-radio
                  class="radio"
                  label="Hotel Canino Grande"
                  value="Hotel Canino Grande"
                ></v-radio>
                <v-radio
                  label="Hotel Canino Mediano"
                  value="Hotel Canino Mediano"
                ></v-radio>
                <v-radio
                  label="Hotel Canino Pequeño"
                  value="Hotel Canino Pequeño"
                ></v-radio>
                <v-radio label="Hotel Felino" value="Hotel Felino"></v-radio>
              </v-radio-group>
            </v-col>
            <v-divider vertical class="mt-6 mr-5 mb-6"></v-divider>
            <!-- seleccion de transporte -->
            <v-col cols="2.5">
              <h2>Transporte</h2>
              <v-radio-group mandatory v-model="selectTransporteHotel">
                <v-radio
                  label="Sí, solicita transporte"
                  value="Solicita transporte"
                ></v-radio>
                <v-radio
                  label="No, el cliente vendra"
                  value="No solicitado"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-divider vertical class="mt-6 mr-5 mb-6"></v-divider>
            <!-- selección de alimento -->
            <v-col cols="2.5">
              <h2>Alimento</h2>
              <v-radio-group mandatory v-model="selectAlimentoHotel">
                <v-radio
                  label="Clínica dará alimento"
                  value="Proporcionado"
                ></v-radio>
                <v-radio
                  label="Cliente traerá alimento"
                  value="No proporcionado"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-divider vertical class="mt-6 mr-5 mb-6"></v-divider>
            <!-- fecha de planifación -->
            <v-col cols="4.5">
              <h2>Planificación</h2>
              <!-- Rango de fecha -->
              <v-menu
                ref="menuDateRangeHotel"
                v-model="menuRangeHotel"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeHotel"
                    label="Rango de fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateRangeHotel"
                  locale="es-ES"
                  range
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuRangeHotel = false">
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuDateRangeHotel.save(date)"
                  >
                    Seleccionar
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <!-- Hora -->
              <div class="d-flex">
                <!-- Entrada -->
                <v-menu
                  ref="menuCheckin"
                  v-model="menuCheckin"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="horaHotelCheckin"
                      label="Check-in"
                      dense
                      class="ma-1"
                      outlined
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menuCheckin"
                    v-model="horaHotelCheckin"
                    format="24hrs"
                    full-width
                    @click:minute="$refs.menuCheckin.save(horaHotelCheckin)"
                  ></v-time-picker>
                </v-menu>
                <!-- Salida -->
                <v-menu
                  ref="menuCheckout"
                  v-model="menuCheckout"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="horaHotelCheckout"
                      label="Check-out"
                      dense
                      class="ma-1"
                      outlined
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menuCheckout"
                    v-model="horaHotelCheckout"
                    format="24hrs"
                    full-width
                    @click:minute="$refs.menuCheckout.save(horaHotelCheckout)"
                  ></v-time-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="certificadoRadiologico">
          <h2>Certificado Radiológico</h2>
          <v-textarea
            label="Examen solicitado"
            v-model="descriptionExamensolicitadoRadiologico"
            class="ma-1"
            outlined
            dense
          ></v-textarea>
          <v-textarea
            label="Antecedentes"
            v-model="descriptionAntecedentesRadiologico"
            rows="2"
            outlined
            dense
            class="ma-1"
          ></v-textarea>
          <v-textarea
            v-model="descriptionInterpretacionRadiologico"
            label="Interpretación"
            rows="4"
            outlined
            dense
            class="ma-1"
          ></v-textarea>
          <v-textarea
            v-model="descriptionRadiodiagnosticoRadiologico"
            label="Radiodiagnóstico"
            rows="4"
            outlined
            dense
            class="ma-1"
          ></v-textarea>
        </div>
        <div v-if="certificadoVacuna">
          <h2>Certificado de vacunación</h2>
          <div class="d-flex">
            <v-autocomplete
              label="Seleccione vacuna"
              :items="filterProdus"
              v-model="selectedVacuna"
              item-text="Descripcion"
              item-value="Descripcion"
              outlined
              dense
              class="ma-1"
            ></v-autocomplete>
            <!-- fecha de vacuna -->
            <v-menu
              ref="menuDateVacuna"
              v-model="menuDateVacuna"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateVacunaAplicada"
                  label="Fecha aplicación"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  class="ma-1"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateVacunaAplicada"
                locale="es-ES"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuRangeHotel = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuDateVacuna.save(date)"
                >
                  Seleccionar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </div>
        <div v-if="cerficadoEspecial">
          <h2>Certificado especial</h2>
          <div class="d-flex">
            <v-text-field
              v-model="certificadoName"
              placeholder="Ingrese solo el nombre del certificado"
              class="ma-1 mt-2"
              dense
              outlined
            ></v-text-field>
          </div>
        </div>
        <div v-if="certificadoImplante">
          <h2>Certificado de implante microchip</h2>
          <div class="d-flex">
            <v-row class="my-4">
              <v-col cols="2.5">
                <h2>Tipo de implantación</h2>
                <v-radio-group mandatory v-model="selectTipoImplante">
                  <v-radio
                    class="radio"
                    label="Se realiza implantación del microchip"
                    value="Se realiza implantación del microchip"
                  ></v-radio>
                  <v-radio
                    label="Tiene implantado el microchip"
                    value="Tiene implantado el microchip"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col>
                <h2>N° del micropchip</h2>
                <v-alert
                  border="left"
                  v-if="errorMicrochip"
                  colored-border
                  type="error"
                  elevation="2"
                  class="ma-2"
                >
                  Error, el chip debe tener 15 dígitos
                </v-alert>
                <div>
                  <h3 class="mt-5 ma-2">Información del Microchip</h3>
                  <div
                    class="ma-0 mt-2 pa-0 d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                  >
                    <v-text-field
                      v-model="microchip"
                      outlined
                      dense
                      type="number"
                      class="ma-2"
                      label="Número de chip"
                    >
                    </v-text-field>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- Contenido del certificado (formulario) -->
        <div>
          <v-textarea
            v-model="descriptionCertificado"
            class="ma-2"
            placeholder="Descripción"
            outlined
          ></v-textarea>
        </div>
        <!-- Botón imprimir -->
        <div class="d-flex justify-end">
          <v-btn
            color="primary"
            @click="btnGenerateCertificado"
            class="ma-2 text-capitalize rounded-lg"
            >Generar</v-btn
          >
        </div>
      </div>
    </v-card>
    <!-- Documento a imprimir -->
    <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="certificado"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="A4"
        pdf-orientation="portrait"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="generateCertificado"
      >
        <section slot="pdf-content">
          <div class="contentrecetaA4">
            <div class="headerA4">
              <div class="containt_headerA4">
                <strong class="main_titleA4">{{ certificadoName }}</strong>
                <div class="containt_logoA4">
                  <img class="logoA4" :src="logoUpload" height="50px" />
                </div>
              </div>
            </div>
            <!-- Subtítulo-->
            <div class="subtitleA4">
              <!-- Primera columna (datos receta) -->
              <div class="firstColumnSubtitle">
                <div class="id_containerA4">
                  <strong>ID:</strong>
                  <p class="idA4">{{ docId }}</p>
                </div>
                <div class="date_containerA4">
                  <strong>Fecha de emisión:</strong>
                  <p class="fechaA4">{{ setDateShortString(today) }}</p>
                </div>
                <div class="amo_containerA4">
                  <strong>Propietario(a):</strong>
                  <div class="amoA4">{{ cliente.Amo }}</div>
                </div>
                <div class="run_containerA4">
                  <strong>RUN:</strong>
                  <div class="runA4">{{ cliente.Rut }}</div>
                </div>
              </div>
              <!-- Segunda columna (información empresa) -->
              <div class="secondColumnSubtitle">
                <div class="logoContainer">
                  <div class="companyData">
                    <div>{{ clinica.Nombre }}</div>
                    <div>{{ clinica.Direccion }} , {{ clinica.Ciudad }}</div>
                    <div>{{ clinica.Fono }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Línea divisoria -->
            <hr />
            <!-- Datos paciente -->
            <div class="customersectionA4">
              <div> 

              </div>


              <div class="first_row_mascota_dato">
                <div class="columnA4bigger">
                  <strong>Ficha / Paciente</strong>
                  <p class="strong_title">
                    {{ cliente.Fichan }} - {{ cliente.Nombre }}
                  </p>
                </div>
                <div class="columnA4">
                  <strong>Microchip</strong>
                  <p class="colInformation">
                    {{ cliente.Microchip }}
                  </p>
                </div>
                <div class="columnA4">
                  <strong>Edad / sexo</strong>
                  <p class="colInformation">{{ getAge(cliente.Nacimiento) }}</p>
                  <p class="colInformation">{{ cliente.Sexo }}</p>
                </div>
              </div>
              <div class="second_row_mascota_dato">
                <div class="columnA4_second_row">
                  <strong>Especie / Raza</strong>
                  <p>
                    {{ cliente.Esp }}
                    {{ cliente.Raza }}
                  </p>
                </div>
                <div class="columnA4_second_row_address">
                  <strong>Dirección</strong>
                  <p class="colInformation">
                    {{ cliente.Direccion }}
                    {{ cliente.Ciudad }}
                    {{ cliente.Comuna }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Línea divisoria -->
            <hr />
            <!-- Contenido principal del certificado -->
            <div class="cuerpoFormulario">
              <!-- Variación Certificado radiológico -->
              <div v-if="certificadoRadiologico">
                <div class="certificadoDataSmaller">
                  <strong>Examen solicitado</strong>
                  <p
                    v-html="setDataExamensolicitadoRadiologico"
                    class="radioContent"
                  ></p>
                </div>
                <br />
                <div class="certificadoData">
                  <strong>Antecedentes</strong>
                  <p
                    v-html="setDataAntecendesRadiologico"
                    class="radioContent"
                  ></p>
                </div>
                <br />
                <div class="certificadoData">
                  <strong>Interpretación</strong>
                  <p
                    class="radioContent"
                    v-html="setDataInterpretacionRadiologico"
                  ></p>
                </div>
                <br />
                <div class="certificadoData">
                  <strong>Radiodiagnóstico</strong>
                  <p
                    class="radioContent"
                    v-html="setDataRadiodiagnosticoRadiologico"
                  ></p>
                </div>
              </div>
              <!-- Variación Certificado hotel -->
              <div v-if="certificadoHotel">
                <!-- Ingreso y salida -->
                <div class="ingresoSalidaContainer">
                  <div class="checkinContainer">
                    <strong>Ingreso:</strong>
                    <p class="checkin">
                      {{
                        setDateStringPrint(
                          dateRangeHotel[0] + "T" + horaHotelCheckin
                        )
                      }}
                    </p>
                  </div>
                  <div class="checkoutContainer">
                    <strong>Salida:</strong>
                    <p class="checkout">
                      {{
                        setDateStringPrint(
                          dateRangeHotel[1] + "T" + horaHotelCheckout
                        )
                      }}
                    </p>
                  </div>
                </div>
                <!-- Datos de planificación -->
                <div class="sectionDataPlanning">
                  <div class="column">
                    <strong>Días</strong>
                    <p class="colInformation">{{ calculeDayzRangeHotel }}</p>
                  </div>
                  <div class="column">
                    <strong>Estadía</strong>
                    <p class="colInformation">{{ selectEstadia }}</p>
                  </div>
                  <div class="column">
                    <strong>Transporte</strong>
                    <p class="colInformation">{{ selectTransporteHotel }}</p>
                  </div>
                  <div class="column">
                    <strong>Alimento</strong>
                    <p class="colInformation">{{ selectAlimentoHotel }}</p>
                  </div>
                </div>
                <!-- Línea divisoria -->
                <hr />
              </div>
              <!-- Variación Certificado vacuna -->
              <div v-if="certificadoVacuna">
                <div class="vaccineContainer">
                  <strong>Vacuna y fecha de aplicación:</strong>
                  <p class="vaccine">
                    {{ selectedVacuna }}
                    {{
                      setDateStringShortPrint(dateVacunaAplicada + "T00:00:00")
                    }}
                  </p>
                </div>
              </div>
              <div v-if="certificadoImplante">
                <div class="vaccineContainer">
                  <strong>Tipo de implantación:</strong>
                  <p class="vaccine">
                    {{ selectTipoImplante }}
                  </p>
                  <br />
                  <strong>N° microchip</strong>
                  <p class="vaccine">
                    {{ microchip }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Descripción (todos los Certificados) -->
            <div class="descriptionContainer">
              <strong>Descripción</strong>
              <p class="description" v-html="setData"></p>
            </div>
            <!-- Línea divisoria -->
            <hr />
            <!-- Datos médico tratante -->
            <div class="dr_data">
              <div class="icon">
                <img src="/icons/vet_symbol.svg" width="50px" height="50px" />
              </div>
              <div class="contenedor-firma">
                <img :src="licen.Firma" height="100px" />
                <div class="texto-encina">
                  <strong>Dr. {{ atenciondia.Nombredoc }}</strong>
                  <p>RUN {{ atenciondia.Rutdoc }}</p>
                </div>
              </div>
            </div>
            <hr />
            <!-- Pie -->
            <div class="footer-verifyA4">
              <div class="first_column_footer">
                <strong
                  >Verifique este documento escaneando el código qr</strong
                >
                <div class="verify_document">
                  <p>https://dash.clivenapp.cl/verify-doc/{{ docId }}</p>
                </div>
              </div>
              <div>
                <qrcode
                  :value="`https://dash.clivenapp.cl/verify-doc/${docId}`"
                  :options="{ width: 120 }"
                ></qrcode>
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "GenerateCertificado",
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      today: new Date().toISOString(),
      selectCert: 0,
      certificados: [
        {
          name: "Certificado de Alta",
          id: 1,
          Var: "Var29",
          Title: "Certificado De Alta",
        },
        {
          name: "Certificado de Cirugía",
          id: 2,
          Var: "Var25",
          Title: "Certificado De Cirugía",
        },
        {
          name: "Certificado de Ecografía General",
          id: 3,
          Var: "Var28b",
          Title: "Certificado De Ecografía",
        },
        {
          name: "Certificado de Endoscopía",
          id: 4,
          Var: "Var28c",
          Title: "Certificado De Endoscopía",
        },
        {
          name: "Certificado de Estadía en Hotel",
          id: 5,
          Var: "Var29",
          Title: "Certificado De Hotel",
        },
        {
          name: "Certificado de Eutanasia",
          id: 6,
          Var: "Var27",
          Title: "Certificado De Eutanasia",
        },
        {
          name: "Certificado especial",
          id: 7,
          Var: "Var28d",
          Title: "Examen Especial",
        },
        {
          name: "Certificado de Hospitalización",
          id: 8,
          Var: "Var28",
          Title: "Certificado De Hospitalización",
        },
        {
          name: "Certificado Radiológico",
          id: 9,
          Var: "Var29",
          Title: "Certificado Radiológico",
        },
        {
          name: "Certificado de Salud",
          id: 10,
          Var: "Var29",
          Title: "Certificado De Salud",
        },
        {
          name: "Certificado de Vacuna",
          id: 11,
          Var: "Var28a",
          Title: "Certificado De Vacuna",
        },
        {
          name: "Certificado de microchip",
          id: 12,
          Var: "Var28a",
          Title: "Certificado De Implante Microchip",
        },
      ],
      certificadoHotel: false,
      certificadoRadiologico: false,
      certifcadoAlta: false,
      certificadoVacuna: false,
      cerficadoEspecial: false,
      certificadoImplante: false,
      certificadoName: "",

      //menu seleccion de fecha
      menuRangeHotel: false,
      dateRangeHotel: [],
      menuCheckin: false,
      horaHotelCheckin: "",
      menuCheckout: false,
      horaHotelCheckout: "",
      descriptionCertificado: "",

      selectedVacuna: "",
      certificadoVacuna: false,
      selectTipoImplante: "",
      //hotel radio
      selectEstadia: "",
      selectTransporteHotel: "",
      selectAlimentoHotel: "",

      //examen radiologoico
      descriptionExamensolicitadoRadiologico: "",
      descriptionAntecedentesRadiologico: "",
      descriptionInterpretacionRadiologico: "",
      descriptionRadiodiagnosticoRadiologico: "",

      //vacuna
      dateVacunaAplicada: "",
      menuDateVacuna: false,

      docId: "",
      microchip: "",
      //microchip valido
      chipValid: false,
      errorMicrochip: false,
    };
  },
  watch: {
    microchip: function (val) {
      if (val.length != 15) {
        this.errorMicrochip = true;
        this.chipValid = false;
      } else {
        this.errorMicrochip = false;
        this.chipValid = true;
        this.microchip = val;
      }
    },
  },
  methods: {
    ...mapActions("medical-module", ["postEdocument"]),
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    },
    disableShowGenerateCertificado() {
      this.$parent.showGenerateCertificado = false;
    },
    setDateShortString(date) {
      var dataSet = new Date(date.substring(0, 10) + "T00:00:00");
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " de " + year;
    },
    selectCertificado() {
      if (this.selectCert == 5) {
        this.certificadoHotel = true;
        this.certificadoRadiologico = false;
        this.cerficadoEspecial = false;
        this.certificadoVacuna = false;
        this.certifcadoAlta = false;
        this.certificadoImplante = false;
      }
      if (this.selectCert == 9) {
        this.certificadoHotel = false;
        this.certificadoRadiologico = true;
        this.cerficadoEspecial = false;
        this.certificadoVacuna = false;
        this.certifcadoAlta = false;
        this.certificadoImplante = false;
      }
      if (this.selectCert == 11) {
        this.certificadoVacuna = true;
        this.certifcadoAlta = false;
        this.certificadoHotel = false;
        this.certificadoRadiologico = false;
        this.cerficadoEspecial = false;
        this.certificadoImplante = false;
      }
      if (this.selectCert == 7) {
        this.cerficadoEspecial = true;
        this.certificadoVacuna = false;
        this.certifcadoAlta = false;
        this.certificadoHotel = false;
        this.certificadoRadiologico = false;
        this.certificadoImplante = false;
      }
      if (this.selectCert == 12) {
        this.certificadoImplante = true;
        this.cerficadoEspecial = false;
        this.certificadoVacuna = false;
        this.certifcadoAlta = false;
        this.certificadoHotel = false;
        this.certificadoRadiologico = false;
      }
      let varCert = this.certificados.find(
        (item) => item.id == this.selectCert
      ).Var;
      this.certificadoName = this.certificados.find(
        (item) => item.id == this.selectCert
      ).name;
      //set de nombre
      if (this.certificadoName == "Examen Especial") {
        this.certificadoName = "";
      }
      this.descriptionCertificado = this.memos[varCert];
      if (
        this.selectCert == 1 ||
        this.selectCert == 2 ||
        this.selectCert == 3 ||
        this.selectCert == 4 ||
        this.selectCert == 6 ||
        this.selectCert == 8 ||
        this.selectCert == 10
      ) {
        this.certificadoHotel = false;
        this.certificadoRadiologico = false;
        this.cerficadoEspecial = false;
        this.certificadoVacuna = false;
        this.certifcadoAlta = false;
      }
    },
    btnGenerateCertificado() {
      this.docId = this.generateUUID();
      var edocument = {
        Id: this.docId,
        TipoDoc: "certificado",
        Tipo_certificado: "",
        Cliente_nombre: this.cliente.Amo,
        Cliente_rut: this.cliente.Rut,
        Cliente_direccion: this.cliente.Direccion + ", " + this.cliente.Comuna,
        Cliente_comuna: this.cliente.Comuna,
        Cliente_ciudad: this.cliente.Ciudad,
        Cliente_mascota: this.cliente.Nombre,
        Cliente_ficha: this.cliente.Fichan,
        Mascota_nacimiento: "2001-01-01 00:00:00",
        Mascota_color: this.cliente.Color,
        Microchip: this.cliente.Microchip,
        Mascota_sexo: this.cliente.Sexo,
        Mascota_raza: this.cliente.Raza,
        Mascota_especie: this.cliente.Esp,
        Doctor_nombre: this.atenciondia.Nombredoc,
        Doctor_rut: this.atenciondia.Rutdoc,
        Clinica: this.clinicaUser.Name,
        Clinica_sucursal: this.user.Sucursal,
        Prescripcion: "",
        Certificado_titulo: this.certificadoName,
        Certificado_descripcion: this.descriptionCertificado,
        Certificado_hotel_ingreso: "2001-01-01 00:00:00",
        Certificado_hotel_salida: "2001-01-01 00:00:00",
        Certificado_hotel_estadia: this.selectEstadia,
        Certificado_hotel_alimento: this.selectAlimentoHotel,
        Certificado_hotel_transporte: this.selectTransporteHotel,
        Certificado_radio_examen: this.descriptionExamensolicitadoRadiologico,
        Certificado_radio_antecedente: this.descriptionAntecedentesRadiologico,
        Certificado_radio_interpretacion:
          this.descriptionInterpretacionRadiologico,
        Certificado_radio_radiodiagnostico:
          this.descriptionRadiodiagnosticoRadiologico,
        Certificado_vacuna_nombre: this.selectedVacuna,
        Certificado_vacuna_fecha: "2001-01-01 00:00:00",
        Fecha_creacion: "",
      };
      if (this.certificadoRadiologico) {
        edocument.Tipo_certificado = "radiologico";
      }
      if (this.certificadoHotel) {
        edocument.Tipo_certificado = "hotel";
      }
      if (this.certificadoVacuna) {
        edocument.Tipo_certificado = "vacuna";
      }
      if (this.dateRangeHotel.length > 0) {
        edocument.Certificado_hotel_ingreso =
          this.dateRangeHotel[0] + " " + this.horaHotelCheckin;
        edocument.Certificado_hotel_salida =
          this.dateRangeHotel[1] + " " + this.horaHotelCheckout;
      }
      if (this.dateVacunaAplicada) {
        edocument.Certificado_vacuna_fecha =
          this.dateVacunaAplicada + " 00:00:00";
      }
      if (this.cliente.Nacimiento) {
        edocument.Mascota_nacimiento = this.cliente.Nacimiento;
      }
      this.postEdocument({ edocument: edocument });
      this.$refs.generateCertificado.generatePdf();
    },
    setDateString(date) {
      var dataSet = new Date(date);
      dataSet = this.setDateNow(dataSet);
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year + " a las " + h + ":" + m;
    },
    setDateStringPrint(date) {
      var dataSet = new Date(date);
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return (
        day +
        " de " +
        montTex +
        " del " +
        year +
        " a las " +
        h +
        ":" +
        m +
        " horas"
      );
    },
    setDateStringShortPrint(date) {
      var dataSet = new Date(date);
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year;
    },
    setDateNow(date) {
      var currentDateObj = date;
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj;
    },
    getAge(dateString) {
      var now = new Date();
      var today = new Date(now.getYear(), now.getMonth(), now.getDate());

      var yearNow = now.getYear();
      var monthNow = now.getMonth();
      var dateNow = now.getDate();

      var dob = new Date(
        dateString.substring(0, 4),
        dateString.substring(5, 7) - 1,
        dateString.substring(8, 10)
      );

      var yearDob = dob.getYear();
      var monthDob = dob.getMonth();
      var dateDob = dob.getDate();
      var age = {};
      var ageString = "";
      var yearString = "";
      var monthString = "";
      var dayString = "";

      let yearAge = yearNow - yearDob;

      if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
      else {
        yearAge--;
        var monthAge = 12 + monthNow - monthDob;
      }

      if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
      else {
        monthAge--;
        var dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge,
      };

      if (age.years > 1) yearString = " años";
      else yearString = " año";
      if (age.months > 1) monthString = " meses";
      else monthString = " mes";
      if (age.days > 1) dayString = " días";
      else dayString = " día";

      if (age.years > 0 && age.months > 0 && age.days > 0)
        ageString =
          age.years +
          yearString +
          ", " +
          age.months +
          monthString +
          ", y " +
          age.days +
          dayString;
      else if (age.years == 0 && age.months == 0 && age.days > 0)
        ageString = "solo " + age.days + dayString;
      else if (age.years > 0 && age.months == 0 && age.days == 0)
        ageString = age.years + yearString + " Feliz cumpleaños!!";
      else if (age.years > 0 && age.months > 0 && age.days == 0)
        ageString = age.years + yearString + " y " + age.months + monthString;
      else if (age.years == 0 && age.months > 0 && age.days > 0)
        ageString =
          age.months + monthString + " y " + age.days + dayString + ".";
      else if (age.years > 0 && age.months == 0 && age.days > 0)
        ageString = age.years + yearString + " y " + age.days + dayString + ".";
      else if (age.years == 0 && age.months > 0 && age.days == 0)
        ageString = age.months + monthString + " .";
      else ageString = "Oops! No es posible calcular!";

      return ageString;
    },
  },
  computed: {
    ...mapState("medical-module", ["produs", "memos", "atenciondia"]),
    ...mapState("customers-module", ["cliente"]),
    ...mapState("auth-module", [
      "logoUpload",
      "clinica",
      "licen",
      "user",
      "clinicaUser",
    ]),
    filterProdus() {
      return this.produs.filter((item) => item.Ubicacion == "VACUNAS");
    },
    setData() {
      return this.descriptionCertificado.replace(/(?:\r\n|\r|\n)/g, "<br/>");
    },
    setDataExamensolicitadoRadiologico() {
      return this.descriptionExamensolicitadoRadiologico.replace(
        /(?:\r\n|\r|\n)/g,
        "<br/>"
      );
    },
    setDataAntecendesRadiologico() {
      return this.descriptionAntecedentesRadiologico.replace(
        /(?:\r\n|\r|\n)/g,
        "<br/>"
      );
    },
    setDataInterpretacionRadiologico() {
      return this.descriptionInterpretacionRadiologico.replace(
        /(?:\r\n|\r|\n)/g,
        "<br/>"
      );
    },
    setDataRadiodiagnosticoRadiologico() {
      return this.descriptionRadiodiagnosticoRadiologico.replace(
        /(?:\r\n|\r|\n)/g,
        "<br/>"
      );
    },

    calculeDayzRangeHotel() {
      let start_date = this.dateRangeHotel[0];
      let end_date = this.dateRangeHotel[1];
      let set_start_date = new Date(
        start_date + "T" + this.horaHotelCheckin
      ).getTime();
      let set_end_date = new Date(
        end_date + "T" + this.horaHotelCheckout
      ).getTime();
      var diff = set_end_date - set_start_date;
      return Math.floor(diff / (1000 * 60 * 60 * 24));
    },
  },
  created() {},
};
</script>
<style>
/* Contenido receta a4 */
strong {
  font-weight: 800;
  letter-spacing: 2px;
}
.contentrecetaA4 {
  height: 1000px;
}
/* Encabezado */
.headerA4 {
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 10px;
  height: 10%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.headerLogo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.main_titleA4 {
  color: #0d47a1;
  font-weight: bolder;
  letter-spacing: 2px;
  font-size: 16px;
}
/* Subtítulo */
.subtitleA4 {
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 15px;
}
.id_containerA4 {
  display: flex;
}
.idA4 {
  margin-left: 3px;
}
.fechaA4 {
  margin-left: 3px;
}
.date_containerA4 {
  display: flex;
  margin-top: 3px;
}
.amo_containerA4 {
  display: flex;
  margin-top: 10px;
}
.amoA4 {
  margin: 0px;
  margin-left: 3px;
}
.run_containerA4 {
  display: flex;
}
.runA4 {
  margin: 0px;
  margin-left: 3px;
}
/* Fin encabezado */

/* Datos paciente */
.customersectionA4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  height: 10%;
}
.addressA4 {
  padding-right: 10px;
}
.first_row_mascota_dato {
  display: flex;
}
.second_row_mascota_dato {
  display: flex;
  padding-top: 20px;
}
.columnA4 {
  width: 40%;
}
.columnA4_second_row {
  width: 37%;
}
.columnA4bigger {
  width: 46%;
}
/* Fin datos paciente */

/* Contenido principal certificado */
.cuerpoFormulario {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 10px;
}
/* Variación Certificado de Estadía en Hotel */
/* Ingreso/salida */
.ingresoSalidaContainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-bottom: 20px;
}
.checkinContainer {
  display: flex;
}
.checkoutContainer {
  display: flex;
  margin-top: 3px;
}
.checkin {
  margin-left: 3px;
}
.checkout {
  margin-left: 3px;
}
/* Fin ingreso/salida */
/* Datos de planificación */
.sectionDataPlanning {
  display: flex;
  margin-bottom: 10px;
  height: 20%;
}
.column {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.colInformation {
  margin-top: 3px;
}
/* Fin descripción */
/* Fin variación Estadía en Hotel */

/* Variación Certificado Radiológico */
.certificadoDataSmaller {
  height: 15%;
}
.certificadoData {
  height: 27%;
  padding-bottom: 10px;
  margin-top: 3px;
}
/* Fin variación Certificado Radiológico */

/* Variación Certificado Vacuna */
.vaccineContainer {
  display: flex;
  flex-direction: column;
  height: 30%;
}
.vaccine {
  margin-top: 3px;
}
/* Fin variación Certificado Vacuna */

/* Fin datos de planificación */
/* Descripción */
.descriptionContainer {
  margin-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  height: 34%;
}
.description {
  margin-top: 3px;
}
/* Datos médico tratante */
.dr_data {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 10%;
}
.icon {
  margin-right: 10px;
}
/* Fin datos médico tratante */
/* Línea divisoria (horizontal) */
hr {
  margin: auto;
  border: 0.2px solid #e9ebe9;
  width: 95%;
}
/* Pie (footer)  */
.footer-verifyA4 {
  display: flex;
  width: 867px;
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 20px;
  height: 10%;
  align-content: center;
}
.first_column_footer {
  width: 60%;
}
.verify_document {
  margin-top: 10px;
  margin-right: 100px;
  background: #f7f7f7;
  padding: 5px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.msg_qr {
  padding-top: 20px;
}
.second_column_footer {
  width: 40%;
}
.qr_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.qr {
  margin-top: 10px;
  width: 80px;
}
/* Fin pie */
/* Fin contenido A4 */
</style>
